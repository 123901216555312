export const WORDS = [
  "AGENT",
  "WORLD",
  "ABOUT",
  "HEART",
  "WATER",
  "SIXTY",
  "BOARD",
  "MONTH",
  "MUSIC",
  "PARTY",
  "PIANO",
  "MOUTH",
  "WOMAN",
  "SUGAR",
  "AMBER",
  "DREAM",
  "LAUGH",
  "TIGER",
  "EARTH",
  "MONEY",
  "WORDS",
  "SMILE",
  "LEMON",
  "SOUTH",
  "AFTER",
  "STONE",
  "THING",
  "LIGHT",
  "STORY",
  "POWER",
  "TODAY",
  "RANGE",
  "PEARL",
  "VENOM",
  "PROXY",
  "ROUND",
  "HOVER",
  "CANDY",
  "ABOVE",
  "PHONE",
  "OTHER",
  "SMART",
  "BLACK",
  "MAGIC",
  "FRUIT",
  "RADIO",
  "ROYAL",
  "HONEY",
  "FLAKE",
  "SOUND",
];

export const WORDSTR = [
  "HABBE",
  "HABER",
  "HABEŞ",
  "HABİP",
  "HABİS",
  "HACET",
  "HACİM",
  "HACİR",
  "HACİZ",
  "HAÇLI",
  "HADDE",
  "HADIM",
  "HADİM",
  "HADİS",
  "HAFIZ",
  "HAFİF",
  "HAFİK",
  "HAFİT",
  "HAFTA",
  "HAHAM",
  "HAHHA",
  "HAİLE",
  "HAKAN",
  "HAKAS",
  "HAKÇA",
  "HAKEM",
  "HAKİM",
  "HAKİR",
  "HAKLI",
  "HALAÇ",
  "HALAS",
  "HALAT",
  "HALAY",
  "HALEF",
  "HALEL",
  "HALEN",
  "HALET",
  "HALFA",
  "HALİÇ",
  "HALİK",
  "HALİM",
  "HALİS",
  "HALKA",
  "HALUK",
  "HAMAK",
  "HAMAL",
  "HAMAM",
  "HAMEL",
  "HAMIZ",
  "HAMİL",
  "HAMİŞ",
  "HAMLA",
  "HAMLE",
  "HAMSE",
  "HAMSİ",
  "HAMUR",
  "HAMUT",
  "HANAK",
  "HANAY",
  "HANCI",
  "HANDE",
  "HANEK",
  "HANGİ",
  "HANIM",
  "HANUT",
  "HANYA",
  "HAPAZ",
  "HAPÇI",
  "HAPİS",
  "HAPŞU",
  "HARAÇ",
  "HARAM",
  "HARAP",
  "HARAR",
  "HARBE",
  "HARBİ",
  "HAREM",
  "HARIM",
  "HARIN",
  "HARİÇ",
  "HARİM",
  "HARİR",
  "HARİS",
  "HARLI",
  "HARTA",
  "HASAR",
  "HASAT",
  "HASBİ",
  "HASEP",
  "HASET",
  "HASIL",
  "HASIM",
  "HASIR",
  "HASİS",
  "HASPA",
  "HASSA",
  "HASSE",
  "HASTA",
  "HASUT",
  "HAŞAT",
  "HAŞIL",
  "HAŞİN",
  "HAŞİR",
  "HAŞİŞ",
  "HAŞİV",
  "HATAY",
  "HATIL",
  "HATIR",
  "HATİF",
  "HATİM",
  "HATİP",
  "HATMİ",
  "HATTA",
  "HATUN",
  "HAVAİ",
  "HAVAN",
  "HAVAS",
  "HAVLI",
  "HAVLU",
  "HAVRA",
  "HAVSA",
  "HAVUÇ",
  "HAVUT",
  "HAVUZ",
  "HAVVA",
  "HAVYA",
  "HAVZA",
  "HAYAL",
  "HAYAT",
  "HAYBE",
  "HAYCI",
  "HAYDA",
  "HAYDİ",
  "HAYFA",
  "HAYIF",
  "HAYIR",
  "HAYIT",
  "HAYIZ",
  "HAYLİ",
  "HAYTA",
  "HAZAN",
  "HAZAR",
  "HAZCI",
  "HAZIK",
  "HAZIM",
  "HAZIR",
  "HAZİN",
  "HAZNE",
  "HAZRO",
  "HECİN",
  "HEDEF",
  "HEDER",
  "HEDİK",
  "HEKİM",
  "HELAK",
  "HELAL",
  "HELEN",
  "HELİK",
  "HELİS",
  "HELKE",
  "HELME",
  "HELVA",
  "HEMEN",
  "HEMPA",
  "HEMZE",
  "HENÜZ",
  "HEPSİ",
  "HEREK",
  "HERİF",
  "HERİK",
  "HERKE",
  "HERTZ",
  "HERZE",
  "HESAP",
  "HEVES",
  "HEYBE",
  "HEYET",
  "HEZEL",
  "HEZEN",
  "HIFIZ",
  "HIMIŞ",
  "HINIS",
  "HIRBO",
  "HIRKA",
  "HIRLI",
  "HISIM",
  "HIŞIM",
  "HIŞIR",
  "HIYAR",
  "HIZAR",
  "HIZIR",
  "HIZLA",
  "HIZLI",
  "HIZMA",
  "HİCAP",
  "HİCAZ",
  "HİCİV",
  "HİCRİ",
  "HİÇÇİ",
  "HİDİV",
  "HİDRA",
  "HİKEM",
  "HİLAF",
  "HİLAL",
  "HİLAT",
  "HİLYE",
  "HİMEN",
  "HİNDİ",
  "HİNDU",
  "HİPPİ",
  "HİSAR",
  "HİSLİ",
  "HİSSE",
  "HİSSİ",
  "HİTAM",
  "HİTAN",
  "HİTAP",
  "HİTİT",
  "HİZAN",
  "HİZİP",
  "HODAN",
  "HODRİ",
  "HOKEY",
  "HOKKA",
  "HONAZ",
  "HOPPA",
  "HORON",
  "HOROZ",
  "HORST",
  "HOŞAF",
  "HOŞÇA",
  "HOŞUR",
  "HOTOZ",
  "HOZAN",
  "HOZAT",
  "HÖDÜK",
  "HÖYÜK",
  "HUDUT",
  "HUKUK",
  "HULUL",
  "HULUS",
  "HUMAR",
  "HUMMA",
  "HUMOR",
  "HUMUS",
  "HURDA",
  "HURMA",
  "HURRA",
  "HURUÇ",
  "HUSUF",
  "HUSUL",
  "HUSUS",
  "HUSYE",
  "HUTBE",
  "HUTUT",
  "HUYLU",
  "HUZUR",
  "HÜCRE",
  "HÜCUM",
  "HÜKMİ",
  "HÜKÜM",
  "HÜLLE",
  "HÜLYA",
  "HÜNER",
  "HÜNSA",
  "HÜRLE",
  "HÜRYA",
  "HÜSÜN",
  "HÜYÜK",
  "HÜZME",
  "HÜZÜN",
  "YABAN",
  "YABGU",
  "YAFTA",
  "YAĞAR",
  "YAĞCI",
  "YAĞIR",
  "YAĞIŞ",
  "YAĞIZ",
  "YAĞLI",
  "YAĞMA",
  "YAĞSI",
  "YAHEY",
  "YAHNİ",
  "YAHŞİ",
  "YAHUT",
  "YAKIM",
  "YAKIN",
  "YAKIŞ",
  "YAKIT",
  "YAKİN",
  "YAKMA",
  "YAKUT",
  "YALAK",
  "YALAN",
  "YALAZ",
  "YALIM",
  "YALIN",
  "YALIZ",
  "YALPA",
  "YALPI",
  "YAMAÇ",
  "YAMAK",
  "YAMAN",
  "YAMÇI",
  "YAMUK",
  "YANAK",
  "YANAL",
  "YANAY",
  "YANAZ",
  "YANCI",
  "YANGI",
  "YANIK",
  "YANIŞ",
  "YANIT",
  "YANKI",
  "YANLI",
  "YANMA",
  "YANSI",
  "YAPAK",
  "YAPAY",
  "YAPIK",
  "YAPIM",
  "YAPIŞ",
  "YAPIT",
  "YAPMA",
  "YARAK",
  "YARAN",
  "YARAR",
  "YARAŞ",
  "YARDA",
  "YAREN",
  "YARGI",
  "YARIK",
  "YARIM",
  "YARIN",
  "YARIŞ",
  "YARKA",
  "YARMA",
  "YASAK",
  "YASAL",
  "YASİN",
  "YASLI",
  "YASMA",
  "YASSI",
  "YAŞAM",
  "YAŞÇA",
  "YAŞIT",
  "YAŞLI",
  "YATAK",
  "YATAY",
  "YATÇI",
  "YATIK",
  "YATIM",
  "YATIR",
  "YATIŞ",
  "YATMA",
  "YATSI",
  "YAVAN",
  "YAVAŞ",
  "YAVER",
  "YAVRU",
  "YAVSI",
  "YAVUZ",
  "YAYAN",
  "YAYGI",
  "YAYIK",
  "YAYIM",
  "YAYIN",
  "YAYIŞ",
  "YAYLA",
  "YAYLI",
  "YAYMA",
  "YAZAR",
  "YAZGI",
  "YAZIK",
  "YAZIM",
  "YAZIN",
  "YAZIR",
  "YAZIŞ",
  "YAZIT",
  "YAZMA",
  "YEDEK",
  "YEDİZ",
  "YEDME",
  "YEGAH",
  "YEGAN",
  "YEĞEN",
  "YEĞİN",
  "YEĞNİ",
  "YEKTA",
  "YEKUN",
  "YELEÇ",
  "YELEK",
  "YELİN",
  "YELLİ",
  "YELME",
  "YELVE",
  "YEMCİ",
  "YEMEK",
  "YEMİN",
  "YEMİŞ",
  "YENGE",
  "YENGİ",
  "YENİK",
  "YENLİ",
  "YENME",
  "YEREL",
  "YEREY",
  "YERGİ",
  "YERLİ",
  "YERME",
  "YEŞİL",
  "YEŞİM",
  "YETER",
  "YETİK",
  "YETİM",
  "YETKE",
  "YETKİ",
  "YETME",
  "YEVMİ",
  "ŞABAN",
  "ŞAFAK",
  "ŞAFİİ",
  "ŞAFUL",
  "YEYGİ",
  "YEZİT",
  "YIĞIN",
  "ŞAHAP",
  "ŞAHIS",
  "ŞAHİN",
  "ŞAHİT",
  "ŞAHNE",
  "ŞAHSİ",
  "ŞAİBE",
  "ŞAİRE",
  "ŞAKAK",
  "ŞAKUL",
  "YIĞIŞ",
  "YIĞMA",
  "YIKIK",
  "YIKIM",
  "YIKIŞ",
  "YIKMA",
  "YILAN",
  "ŞALAK",
  "ŞAMAN",
  "ŞAMAR",
  "ŞAMİL",
  "ŞANLI",
  "ŞAPÇI",
  "YILGI",
  "YILIK",
  "YILKI",
  "YILMA",
  "YIRIK",
  "YİĞİT",
  "YİRİK",
  "YİRMİ",
  "ŞAPEL",
  "YİTİK",
  "YİTİM",
  "YİTİŞ",
  "YİTME",
  "YİVLİ",
  "YİYİM",
  "YİYİŞ",
  "YOBAZ",
  "ŞAPKA",
  "ŞAPLI",
  "ŞARAP",
  "ŞARKI",
  "ŞARKİ",
  "ŞARPİ",
  "ŞARYO",
  "YOĞUN",
  "YOKÇU",
  "ŞAŞAA",
  "ŞAŞMA",
  "ŞATIR",
  "ŞAVUL",
  "ŞAYAK",
  "YOKSA",
  "YOKUŞ",
  "YOLAK",
  "YOLCU",
  "YOLLU",
  "YOLMA",
  "YOLUK",
  "YOMRA",
  "YONCA",
  "ŞAYAN",
  "ŞAYET",
  "ŞAYİA",
  "ŞAYKA",
  "ŞEBEK",
  "ŞEDDE",
  "ŞEDİT",
  "YONGA",
  "YONMA",
  "YONTU",
  "YORGA",
  "YORMA",
  "YORTU",
  "YORUM",
  "YOSMA",
  "YOSUN",
  "YÖNLÜ",
  "ŞEFİK",
  "ŞEHİR",
  "ŞEHİT",
  "ŞEHLA",
  "ŞEKEL",
  "ŞEKER",
  "ŞEKİL",
  "ŞEKLİ",
  "ŞEKVA",
  "ŞELEK",
  "YÖRÜK",
  "YUDUM",
  "ŞEMSE",
  "ŞEMSİ",
  "ŞEPİT",
  "ŞERAN",
  "ŞEREF",
  "ŞERHA",
  "ŞERİF",
  "ŞERİK",
  "ŞERİR",
  "YUFKA",
  "YUKAÇ",
  "YULAF",
  "YULAR",
  "YUMAK",
  "YUMMA",
  "YUMRU",
  "YUMUK",
  "YUNAK",
  "ŞERİT",
  "ŞERPA",
  "ŞETİM",
  "ŞINAV",
  "ŞIPKA",
  "ŞIRAK",
  "ŞİFON",
  "ŞİFRE",
  "YUNAN",
  "YUNMA",
  "YUNUS",
  "YURDU",
  "YUTAK",
  "YUTMA",
  "YUTUM",
  "YUTUŞ",
  "YUVAK",
  "ŞİKAR",
  "ŞİLEM",
  "ŞİLEP",
  "ŞİLİN",
  "ŞİLTE",
  "ŞİMAL",
  "ŞİMDİ",
  "ŞİNİK",
  "YUVAR",
  "YUVGU",
  "YÜKÇÜ",
  "YÜKLÜ",
  "YÜKÜM",
  "YÜKÜN",
  "YÜLGÜ",
  "YÜLÜK",
  "ŞİNTO",
  "ŞİRAN",
  "ŞİRİN",
  "ŞİŞEK",
  "ŞİŞİK",
  "ŞİŞKO",
  "ŞİŞLİ",
  "ŞİŞME",
  "YÜNLÜ",
  "YÜREK",
  "YÜRÜK",
  "YÜSRÜ",
  "YÜZDE",
  "ŞOFÖR",
  "ŞOPAR",
  "ŞORCA",
  "YÜZER",
  "YÜZEY",
  "ŞOSET",
  "ŞOSON",
  "ŞOVEN",
  "ŞÖLEN",
  "ŞÖMİZ",
  "ŞÖYLE",
  "YÜZLÜ",
  "YÜZME",
  "YÜZÜK",
  "ŞUARA",
  "ŞUBAT",
  "ŞUHUT",
  "ŞUNCA",
  "ŞURUP",
  "ŞÜKÜR",
  "ŞÜMUL",
  "ŞÜPHE",
  "YÜZÜŞ",
  "ÜCRET",
  "ÜÇGEN",
  "ÜÇGÜL",
  "ÜÇKAT",
  "ÜÇLER",
  "ÜÇLÜK",
  "ÜÇTAŞ",
  "ÜDEBA",
  "ÜFLEÇ",
  "ÜĞRÜM",
  "ÜLFET",
  "ÜLGER",
  "ÜLKER",
  "ÜLSER",
  "ÜMERA",
  "ÜMMET",
  "ÜMRAN",
  "ÜNDEŞ",
  "ÜNİTE",
  "ÜNLEM",
  "ÜNSÜZ",
  "ÜREME",
  "ÜREMİ",
  "ÜRGÜP",
  "ÜRKEK",
  "ÜRKME",
  "ÜRKÜŞ",
  "ÜRÜME",
  "ÜRYAN",
  "ÜSERA",
  "ÜSKÜF",
  "ÜSLUP",
  "ÜSTAT",
  "ÜSTEL",
  "ÜSTLÜ",
  "ÜSTÜN",
  "ÜŞENÇ",
  "ÜŞMEK",
  "ÜŞÜME",
  "ÜTMEK",
  "ÜTÜCÜ",
  "ÜTÜLÜ",
  "ÜZERE",
  "ÜZERİ",
  "ÜZGÜN",
  "ÜZLÜK",
  "ÜZMEK",
  "ÜZÜCÜ",
  "ÜZÜNÇ",
  "SABAH",
  "SABAN",
  "SABIK",
  "SABIR",
  "SABİT",
  "SABUH",
  "SABUN",
  "SABUR",
  "SAÇAK",
  "SAÇIK",
  "SAÇIŞ",
  "SAÇLI",
  "SAÇMA",
  "SADAK",
  "SADET",
  "SADIK",
  "SADIR",
  "SADİK",
  "SADME",
  "SAFÇA",
  "SAFER",
  "SAFHA",
  "SAFİR",
  "SAFRA",
  "SAĞCI",
  "SAĞIM",
  "SAĞIN",
  "SAĞIR",
  "SAĞMA",
  "SAĞRI",
  "SAHAF",
  "SAHAN",
  "SAHİH",
  "SAHİL",
  "SAHİP",
  "SAHNE",
  "SAHRA",
  "SAHRE",
  "SAHTE",
  "SAHUR",
  "SAİKA",
  "SAKAF",
  "SAKAK",
  "SAKAL",
  "SAKAR",
  "SAKAT",
  "SAKIN",
  "SAKIT",
  "SAKIZ",
  "SAKİL",
  "SAKİM",
  "SAKİN",
  "SAKİT",
  "SAKLI",
  "SAKSI",
  "SALAH",
  "SALAK",
  "SALAM",
  "SALAŞ",
  "SALAT",
  "SALCI",
  "SALÇA",
  "SALEP",
  "SALGI",
  "SALIK",
  "SALİK",
  "SALİM",
  "SALİP",
  "SALLI",
  "SALMA",
  "SALON",
  "SALOZ",
  "SALPA",
  "SALSA",
  "SALTA",
  "SALTO",
  "SALUR",
  "SALVO",
  "SALYA",
  "SAMAN",
  "SAMBA",
  "SAMSA",
  "SAMUR",
  "SAMUT",
  "SANAL",
  "SANAT",
  "SANCI",
  "SANEM",
  "SANGI",
  "SANIK",
  "SANIŞ",
  "SANKİ",
  "SANLI",
  "SANMA",
  "SANRI",
  "SAPAK",
  "SAPAN",
  "SAPIK",
  "SAPIŞ",
  "SAPKI",
  "SAPLI",
  "SAPMA",
  "SARAÇ",
  "SARAK",
  "SARAT",
  "SARAY",
  "SARGI",
  "SARIK",
  "SARIM",
  "SARIŞ",
  "SARIZ",
  "SARİG",
  "SARİH",
  "SARMA",
  "SARPA",
  "SASON",
  "SATEN",
  "SATHİ",
  "SATIH",
  "SATIM",
  "SATIR",
  "SATIŞ",
  "SATİR",
  "SATMA",
  "SAUNA",
  "SAVAK",
  "SAVAN",
  "SAVAŞ",
  "SAVAT",
  "SAVCA",
  "SAVCI",
  "SAVLA",
  "SAVLO",
  "SAVMA",
  "SAVUR",
  "SAYAÇ",
  "SAYFA",
  "SAYGI",
  "SAYHA",
  "SAYIM",
  "SAYIN",
  "SAYIŞ",
  "SAYMA",
  "SAYRI",
  "SAZAK",
  "SAZAN",
  "SAZCI",
  "SAZLI",
  "SEANS",
  "SEBAT",
  "SEBEN",
  "SEBEP",
  "SEBİL",
  "SEBZE",
  "SECDE",
  "SEÇAL",
  "SEÇİM",
  "SEÇİŞ",
  "SEÇKİ",
  "SEÇME",
  "SEDEF",
  "SEDİR",
  "SEDYE",
  "SEFER",
  "SEFİH",
  "SEFİL",
  "SEFİR",
  "SEGAH",
  "SEHER",
  "SEHİM",
  "SEHİV",
  "SEHPA",
  "SEKEL",
  "SEKİL",
  "SEKİŞ",
  "SEKİZ",
  "SEKME",
  "SEKSİ",
  "SEKTE",
  "SELAM",
  "SELEF",
  "SELEK",
  "SELEN",
  "SELİM",
  "SELİS",
  "SELVA",
  "SELVİ",
  "SEMAH",
  "SEMAİ",
  "SEMAN",
  "SEMEN",
  "SEMER",
  "SEMİH",
  "SEMİZ",
  "SENCE",
  "SENEK",
  "SENET",
  "SENİR",
  "SENİT",
  "SEPEK",
  "SEPET",
  "SEPYA",
  "SERAK",
  "SERAP",
  "SERÇE",
  "SEREN",
  "SERGİ",
  "SERİK",
  "SERİM",
  "SERİN",
  "SERİŞ",
  "SERME",
  "SERUM",
  "SERVİ",
  "SESÇİ",
  "SESLİ",
  "SETER",
  "SETİK",
  "SETİR",
  "SETRE",
  "SEVAP",
  "SEVDA",
  "SEVER",
  "SEVGİ",
  "SEVİM",
  "SEVİR",
  "SEVİŞ",
  "SEVME",
  "SEYEK",
  "SEYİR",
  "SEYİS",
  "SEYİT",
  "SEZGİ",
  "SEZİŞ",
  "SEZME",
  "TABAK",
  "SEZON",
  "SICAK",
  "SIÇAN",
  "TABAN",
  "TABİİ",
  "TABİP",
  "TABİR",
  "TABLA",
  "TABLO",
  "TABUR",
  "TABUT",
  "TABYA",
  "TACİK",
  "SIÇMA",
  "SIFAT",
  "SIFIR",
  "SIĞIN",
  "SIĞIR",
  "SIĞLA",
  "SIĞMA",
  "SIHHİ",
  "SIHRİ",
  "SIKÇA",
  "SIKIM",
  "TACİL",
  "TACİR",
  "TACİZ",
  "TAÇLI",
  "TADAT",
  "TADIM",
  "TADİL",
  "TAFRA",
  "TAFTA",
  "TAHIL",
  "SIKIT",
  "SIKMA",
  "SIMAK",
  "SINAİ",
  "SINAV",
  "SINDI",
  "SINIF",
  "TAHİN",
  "TAHRA",
  "TAHTA",
  "TAKAS",
  "TAKAT",
  "TAKIK",
  "TAKIM",
  "TAKİM",
  "TAKİP",
  "TAKKE",
  "SINIK",
  "SINIR",
  "SINMA",
  "SIRAT",
  "SIRCI",
  "SIRÇA",
  "SIRIK",
  "TAKLA",
  "TAKMA",
  "TAKOZ",
  "TAKSA",
  "TAKSİ",
  "TAKTİ",
  "TAKVA",
  "SIRIM",
  "SIRLI",
  "SIRMA",
  "SISKA",
  "SITMA",
  "SIVIK",
  "SIYGA",
  "SIYGI",
  "SIZAK",
  "SIZIŞ",
  "TALAK",
  "TALAN",
  "TALAS",
  "TALAŞ",
  "TALAZ",
  "TALEP",
  "TALİH",
  "TALİK",
  "TALİL",
  "TALİM",
  "TALİP",
  "SIZMA",
  "SİBOP",
  "SİCİL",
  "SİCİM",
  "SİDİK",
  "SİFİN",
  "SİFON",
  "SİGAR",
  "SİĞİL",
  "TAMAH",
  "TAMAM",
  "TAMİK",
  "TAMİM",
  "TAMİR",
  "TANEN",
  "TANGO",
  "TANIK",
  "TANIM",
  "SİHİR",
  "SİİRT",
  "SİKKE",
  "SİKME",
  "SİLAH",
  "SİLAJ",
  "SİLGİ",
  "SİLİK",
  "TANIŞ",
  "TANIT",
  "TANİN",
  "TANRI",
  "TAOCU",
  "TAPAN",
  "TAPIŞ",
  "TAPİR",
  "TAPMA",
  "TAPON",
  "SİLİS",
  "SİLİŞ",
  "SİLKİ",
  "SİLLE",
  "SİLME",
  "SİMAV",
  "TARAF",
  "TARAK",
  "TARAŞ",
  "TARAZ",
  "TARET",
  "SİMGE",
  "SİMİT",
  "SİMYA",
  "SİNEK",
  "SİNİK",
  "SİNİR",
  "SİNİŞ",
  "SİNLE",
  "SİNME",
  "TARIM",
  "TARİF",
  "TARİH",
  "TARİK",
  "TARİZ",
  "TARLA",
  "TARTI",
  "TASAR",
  "TASDİ",
  "SİNOP",
  "SİNSİ",
  "SİNÜS",
  "SİPER",
  "SİPSİ",
  "SİREN",
  "SİRKE",
  "TASIM",
  "TASMA",
  "TASNİ",
  "TAŞAK",
  "TAŞÇI",
  "TAŞIL",
  "TAŞIM",
  "SİRMO",
  "SİROZ",
  "SİRTO",
  "SİSLİ",
  "SİTEM",
  "SİTİL",
  "SİVAS",
  "SİVİL",
  "SİVRİ",
  "TAŞIT",
  "TAŞLI",
  "TAŞMA",
  "TAŞRA",
  "TAŞSI",
  "TATAR",
  "TATİL",
  "TATLI",
  "UCUBE",
  "UÇARI",
  "UÇKUN",
  "UÇKUR",
  "UÇMAK",
  "UÇMAN",
  "SİYAH",
  "SİYAK",
  "SİYEK",
  "SİYER",
  "SİYME",
  "SİZCE",
  "SKALA",
  "SKİNK",
  "TATMA",
  "TAVAF",
  "TAVAN",
  "TAVAS",
  "TAVCI",
  "UÇSUZ",
  "UÇUCU",
  "UĞRAK",
  "UĞRAŞ",
  "UĞRUN",
  "UKALA",
  "UKNUM",
  "SLAYT",
  "SOFRA",
  "SOFTA",
  "SOĞAN",
  "SOĞUK",
  "SOKAK",
  "SOKET",
  "SOKMA",
  "SOKRA",
  "SOKUM",
  "TAVIR",
  "TAVİK",
  "TAVİZ",
  "TAVLA",
  "TAVLI",
  "TAVUK",
  "TAVUS",
  "TAYFA",
  "TAYGA",
  "TAYIN",
  "TAYİN",
  "ULAMA",
  "ULEMA",
  "ULUFE",
  "ULUMA",
  "SOKUR",
  "SOKUŞ",
  "SOLAK",
  "SOLCU",
  "SOLMA",
  "SOLUK",
  "SOLUŞ",
  "SOMAK",
  "SOMON",
  "SOMUN",
  "SOMUT",
  "SOMYA",
  "TAYİP",
  "TAZİM",
  "TAZİP",
  "TAZİZ",
  "TEALİ",
  "TEATİ",
  "TEBAA",
  "TEBER",
  "UMACI",
  "UMMAK",
  "UMMAN",
  "UMUMİ",
  "UNLUK",
  "UNSUR",
  "UNVAN",
  "URBAN",
  "URGAN",
  "USANÇ",
  "SONAR",
  "SONAT",
  "SONDA",
  "SONLU",
  "SONRA",
  "SONUÇ",
  "SORGU",
  "SORİT",
  "SORMA",
  "TECİL",
  "TECİM",
  "TEDAİ",
  "TEDİP",
  "TEĞET",
  "TEHİR",
  "TEİST",
  "TEİZM",
  "TEKÇİ",
  "USARE",
  "USKUR",
  "USSAL",
  "UŞKUN",
  "UŞŞAK",
  "UTANÇ",
  "UTMAK",
  "UYARI",
  "UYGAR",
  "UYGUN",
  "UYGUR",
  "SORTİ",
  "SORUM",
  "SORUN",
  "SORUŞ",
  "SOSİS",
  "SOYKA",
  "SOYLU",
  "SOYMA",
  "SOYUM",
  "SOYUŞ",
  "TEKEL",
  "TEKER",
  "TEKİL",
  "TEKİN",
  "TEKİR",
  "TEKİT",
  "TEKKE",
  "TEKLİ",
  "TEKME",
  "TEKNE",
  "UYLUK",
  "UYMAK",
  "UYMAZ",
  "UYRUK",
  "UYSAL",
  "UYUMA",
  "UZAMA",
  "UZLET",
  "UZLUK",
  "SOYUT",
  "SÖĞÜŞ",
  "SÖĞÜT",
  "SÖKEL",
  "SÖKME",
  "SÖKÜK",
  "SÖKÜM",
  "SÖKÜN",
  "TEKST",
  "TELAŞ",
  "TELEF",
  "TELEK",
  "TELEM",
  "TELES",
  "TELİF",
  "TELİN",
  "UZMAN",
  "SÖKÜŞ",
  "SÖLOM",
  "SÖNME",
  "SÖNÜK",
  "SÖNÜM",
  "SÖNÜŞ",
  "SÖVEN",
  "SÖVGÜ",
  "SÖVME",
  "TELİS",
  "TELLİ",
  "SÖVÜŞ",
  "SÖZCE",
  "SÖZCÜ",
  "SÖZDE",
  "SÖZEL",
  "SÖZLÜ",
  "TELSİ",
  "TELVE",
  "TEMAS",
  "TEMEK",
  "TEMEL",
  "TEMİN",
  "TEMİZ",
  "TEMPO",
  "TENGE",
  "SPAZM",
  "SPERM",
  "SPREY",
  "STANT",
  "STATÜ",
  "STENO",
  "TENHA",
  "TENİS",
  "TENOR",
  "TENTE",
  "TENYA",
  "TEORİ",
  "TEPİK",
  "TEPİR",
  "TEPİŞ",
  "STENT",
  "TEPKE",
  "TEPKİ",
  "TEPME",
  "TEPSİ",
  "TERAS",
  "TERBİ",
  "TEREK",
  "TERES",
  "TERFİ",
  "STİLO",
  "STREÇ",
  "STRES",
  "SUARE",
  "SUBAY",
  "SUBRA",
  "TERİM",
  "TERKİ",
  "TERLİ",
  "TERME",
  "TERÖR",
  "TERZİ",
  "TESİR",
  "TESİS",
  "TESİT",
  "TESRİ",
  "SUBYE",
  "SUCUK",
  "SUCUL",
  "SUÇLU",
  "SUDAK",
  "SUDAN",
  "SUFLE",
  "SUKUT",
  "SULAK",
  "SULTA",
  "SULUK",
  "SUMAK",
  "TESTİ",
  "TEŞCİ",
  "TEŞNE",
  "TEŞRİ",
  "TEŞYİ",
  "TETİK",
  "TETİR",
  "SUNAK",
  "SUNGU",
  "SUNMA",
  "SUNTA",
  "SUNUM",
  "TEVDİ",
  "TEVEK",
  "TEVİL",
  "TEVKİ",
  "TEVSİ",
  "TEVZİ",
  "TEYEL",
  "TEYİT",
  "TEYZE",
  "TEZAT",
  "TEZCE",
  "TEZEK",
  "SUNUŞ",
  "SUOKU",
  "SUPAP",
  "SURAT",
  "SURET",
  "SURUÇ",
  "SUSAK",
  "SUSAM",
  "SUSKU",
  "SUSMA",
  "TEZLİ",
  "TIBBİ",
  "TIFIL",
  "TIKAÇ",
  "TIKIM",
  "TIKIR",
  "SUSTA",
  "SUSUŞ",
  "SUSUZ",
  "SUTAŞ",
  "SUTLU",
  "SUVAT",
  "SUYUK",
  "VACİP",
  "VAGON",
  "VAHİM",
  "VAHİT",
  "VAHİY",
  "VAHŞİ",
  "VAKAR",
  "VAKFE",
  "VAKIA",
  "TIKIZ",
  "TIKMA",
  "TIMAR",
  "TINAZ",
  "TINMA",
  "TIPKI",
  "TIRAK",
  "TIRAŞ",
  "TIRIK",
  "TIRIL",
  "TIRIS",
  "SÜBEK",
  "SÜBUT",
  "SÜBYE",
  "SÜCUT",
  "SÜFLİ",
  "SÜĞME",
  "SÜKSE",
  "SÜKUN",
  "SÜKUT",
  "SÜLUK",
  "VAKIF",
  "VAKİT",
  "VAKUM",
  "VAKUR",
  "VALİZ",
  "VALÖR",
  "VANLI",
  "VAPUR",
  "TİFÜS",
  "TİKEL",
  "TİLKİ",
  "TİMÜS",
  "TİNER",
  "TİPİK",
  "SÜLÜK",
  "SÜLÜN",
  "SÜLÜS",
  "SÜMEK",
  "SÜMEN",
  "SÜMER",
  "SÜMÜK",
  "SÜNGÜ",
  "SÜNME",
  "VARAK",
  "VARAN",
  "VARDA",
  "VARGI",
  "VARIŞ",
  "VARİL",
  "VARİS",
  "TİRAJ",
  "TİRAN",
  "TİRAT",
  "TİRİT",
  "TİRİZ",
  "SÜNNİ",
  "SÜPER",
  "SÜRAT",
  "SÜREÇ",
  "SÜREK",
  "SÜRFE",
  "SÜRGÜ",
  "SÜRME",
  "SÜRRE",
  "VARİT",
  "VARMA",
  "VAROŞ",
  "VARTA",
  "TİRLE",
  "TİRSİ",
  "TİRŞE",
  "TİTAN",
  "TİTİZ",
  "TOHUM",
  "SÜRUR",
  "SÜRÜM",
  "SÜRÜŞ",
  "SÜSEN",
  "SÜSLÜ",
  "SÜSME",
  "SÜTÇÜ",
  "SÜTLÜ",
  "SÜTRE",
  "SÜTSÜ",
  "SÜTUN",
  "VARTO",
  "VASAT",
  "VASIF",
  "VASIL",
  "VAŞAK",
  "VATAN",
  "VATKA",
  "TOKAÇ",
  "TOKAT",
  "SÜVEN",
  "SÜVME",
  "SÜYEK",
  "SÜYÜM",
  "SÜZEK",
  "SÜZGÜ",
  "SÜZME",
  "SÜZÜK",
  "VATOZ",
  "VAZIH",
  "VEBAL",
  "VECİH",
  "TOKLU",
  "TOKUZ",
  "TOKYO",
  "TOLGA",
  "TOMAK",
  "TOMAR",
  "TONAJ",
  "TONER",
  "SÜZÜŞ",
  "VECİZ",
  "VEÇHE",
  "VEDİA",
  "VEFAT",
  "TONGA",
  "TONİK",
  "TONLA",
  "TONLU",
  "TONOZ",
  "TONYA",
  "VEHİM",
  "VEKİL",
  "VELET",
  "VELUR",
  "VELUT",
  "VENÜS",
  "VERDİ",
  "VEREM",
  "VEREV",
  "ZABIT",
  "ZABİT",
  "ZAFER",
  "ZAĞAR",
  "ZAĞCI",
  "ZAĞLI",
  "ZAHİR",
  "ZAHİT",
  "TOPAÇ",
  "TOPAK",
  "TOPAL",
  "TOPAZ",
  "TOPÇU",
  "VERGİ",
  "VERİM",
  "VERİŞ",
  "VERİT",
  "VERME",
  "ZALİM",
  "ZAMAN",
  "ZAMİR",
  "ZAMLI",
  "ZAMME",
  "ZANKA",
  "ZANLI",
  "ZARAR",
  "TOPİK",
  "TOPLA",
  "TOPLU",
  "TOPUK",
  "TOPUR",
  "TOPUT",
  "VEZİN",
  "VEZİR",
  "VEZNE",
  "VICIK",
  "VİDEO",
  "ZARCI",
  "ZARİF",
  "ZARSI",
  "ZARTA",
  "ZATEN",
  "ZAYIF",
  "ZEBAN",
  "ZEBRA",
  "TOPUZ",
  "TORAK",
  "TORBA",
  "TORİK",
  "TORNA",
  "TORTU",
  "TORUL",
  "TORUM",
  "TORUN",
  "VİGLA",
  "VİLLA",
  "VİRAJ",
  "VİRAL",
  "VİRAN",
  "VİRÜS",
  "VİSAL",
  "VİSKİ",
  "ZEBUN",
  "ZEBUR",
  "ZECİR",
  "ZECRİ",
  "ZEFİR",
  "ZEHAP",
  "ZEHİR",
  "TOSUN",
  "TOSYA",
  "TOTAL",
  "TOTEM",
  "TOYCA",
  "TOYCU",
  "VİŞNE",
  "VİTES",
  "VİTİR",
  "VİYAK",
  "VİYOL",
  "VİZON",
  "VİZÖR",
  "VOKAL",
  "VOLAN",
  "VOLTA",
  "ZEKAT",
  "ZEKER",
  "ZELİL",
  "ZELVE",
  "ZEMİN",
  "ZENCİ",
  "ZENNE",
  "ABACI",
  "ABADİ",
  "ABALI",
  "ABANA",
  "ABANİ",
  "ABAŞO",
  "TOYGA",
  "TOZAN",
  "TOZLU",
  "TOZMA",
  "TÖREL",
  "TÖREN",
  "TÖRPÜ",
  "TÖVBE",
  "VONOZ",
  "VOTKA",
  "VOYVO",
  "VUKUF",
  "VULVA",
  "VURAÇ",
  "VURGU",
  "VURMA",
  "ZERDE",
  "ZERRE",
  "ZEVAL",
  "ABAZA",
  "ABBAS",
  "ABDAL",
  "ABECE",
  "ABHAZ",
  "ABİDE",
  "TÖZEL",
  "TRAFO",
  "TRAKE",
  "TRANS",
  "TRANŞ",
  "TRATA",
  "TRİKO",
  "VURUK",
  "VURUŞ",
  "VUSUL",
  "ZEVAT",
  "ZEVCE",
  "ZEYİL",
  "ZIBIN",
  "ZIHLI",
  "ZIMBA",
  "ZIMNİ",
  "ZIPIR",
  "ZIPKA",
  "ZIRVA",
  "ZİFAF",
  "ZİFİN",
  "ABİYE",
  "ABLAK",
  "ABONE",
  "ABOSA",
  "ABRAŞ",
  "ABULİ",
  "ACABA",
  "ACARA",
  "ACELE",
  "TROMP",
  "TRÖST",
  "TUFAN",
  "TUGAY",
  "TUĞCU",
  "TUĞLA",
  "TUĞLU",
  "TUĞRA",
  "TUHAF",
  "VUZUH",
  "VÜCUT",
  "VÜRUT",
  "ZİFİR",
  "ZİFOS",
  "ZİGON",
  "ZİGOT",
  "ZİHAF",
  "ZİHİN",
  "ZİHNİ",
  "ZİKİR",
  "ZİLLİ",
  "ZİMMİ",
  "ZİNDE",
  "ACEMİ",
  "ACEZE",
  "ACICA",
  "ACILI",
  "TULUK",
  "TULUM",
  "TULUP",
  "TUMAN",
  "TUMBA",
  "TUNİK",
  "TURAÇ",
  "TURAN",
  "VÜSAT",
  "ZİRAİ",
  "ZİRVE",
  "ZİYAN",
  "ZLOTİ",
  "ZOMBİ",
  "ZORBA",
  "ZORCA",
  "ACIMA",
  "ACİBE",
  "ACUBE",
  "ACUZE",
  "AÇGÖZ",
  "AÇLIK",
  "AÇMAK",
  "AÇMAZ",
  "TURBA",
  "TURBO",
  "TURFA",
  "TURNA",
  "TURNE",
  "TURNO",
  "ZORGU",
  "ZORLA",
  "ZORLU",
  "ZORUN",
  "ZUHUR",
  "ZULÜM",
  "ZURNA",
  "ZÜBDE",
  "ZÜHAL",
  "ZÜHRE",
  "ZÜHUL",
  "ZÜKAM",
  "ZÜLAL",
  "ADALE",
  "ADALI",
  "ADAMA",
  "ADANA",
  "ADEDİ",
  "ADESE",
  "ADETA",
  "ADINA",
  "TURŞU",
  "TURTA",
  "TUTAÇ",
  "TUTAK",
  "TUTAM",
  "TUTAR",
  "TUTKU",
  "TUTMA",
  "TUTUK",
  "TUTUM",
  "TUTUŞ",
  "TUTYA",
  "ZÜLÜF",
  "ZÜMRE",
  "ZÜPPE",
  "ZÜRRA",
  "ZÜYUF",
  "ADRES",
  "ADSAL",
  "ADSIZ",
  "AFAKİ",
  "AFAZİ",
  "AFGAN",
  "AFİFE",
  "AFİLİ",
  "TUVAL",
  "TUYUĞ",
  "TUZAK",
  "TUZCU",
  "TUZLA",
  "TUZLU",
  "TUZSU",
  "TÜFEK",
  "AFİŞE",
  "AFONİ",
  "AFSUN",
  "AFŞAR",
  "AFŞİN",
  "AFTOS",
  "BABAÇ",
  "BABAİ",
  "BACAK",
  "BAÇÇI",
  "TÜMCE",
  "TÜMEL",
  "TÜMEN",
  "TÜMEY",
  "TÜMÖR",
  "TÜMÜR",
  "TÜNEK",
  "TÜNEL",
  "TÜNME",
  "TÜPÇÜ",
  "TÜPLÜ",
  "TÜRAP",
  "AFYON",
  "AGAMİ",
  "AGORA",
  "AGRAF",
  "AĞCIK",
  "AĞILI",
  "AĞLAK",
  "AĞMAK",
  "AĞNAM",
  "BADAL",
  "BADAS",
  "BADAT",
  "BADEM",
  "BADIÇ",
  "BADİK",
  "BADYA",
  "BAFRA",
  "TÜRBE",
  "AĞRAZ",
  "AĞYAR",
  "AHALİ",
  "AHBAP",
  "AHCAR",
  "BAGAJ",
  "BAGET",
  "BAĞAN",
  "BAĞCI",
  "BAĞDA",
  "BAĞIL",
  "TÜREL",
  "TÜREV",
  "TÜRKİ",
  "TÜRKÜ",
  "TÜRLÜ",
  "TÜRÜM",
  "TÜTME",
  "TÜTSÜ",
  "TÜTÜN",
  "TÜVİT",
  "AHENK",
  "AHFAT",
  "AHFEŞ",
  "AHİZE",
  "AHKAM",
  "BAĞIM",
  "BAĞIN",
  "BAĞIR",
  "BAĞIŞ",
  "BAĞIT",
  "BAĞLI",
  "BAHAİ",
  "BAHAR",
  "BAHÇE",
  "BAHİR",
  "BAHİS",
  "BAHRİ",
  "TÜYLÜ",
  "TÜYME",
  "TÜZEL",
  "TÜZÜK",
  "TVİST",
  "AHLAF",
  "AHLAK",
  "AHLAT",
  "AHMAK",
  "AHRAZ",
  "AHŞAP",
  "AHVAL",
  "AİDAT",
  "AJANS",
  "AJİTE",
  "BAKAÇ",
  "BAKAM",
  "BAKAN",
  "BAKIM",
  "BAKIR",
  "BAKIŞ",
  "BAKİR",
  "BAKLA",
  "BAKMA",
  "BALAR",
  "BALAT",
  "AKABE",
  "AKAİT",
  "AKAJU",
  "AKALA",
  "AKBAŞ",
  "AKÇIL",
  "AKDUT",
  "AKEMİ",
  "AKICI",
  "BALCI",
  "BALDO",
  "BALET",
  "BALIK",
  "BALİĞ",
  "BALKI",
  "BALLI",
  "BALON",
  "BALOZ",
  "BALTA",
  "AKİDE",
  "AKKOR",
  "AKKÖY",
  "AKKUŞ",
  "AKLAN",
  "AKLEN",
  "AKLIK",
  "AKMAK",
  "AKMAN",
  "AKMAZ",
  "BALYA",
  "BAMBU",
  "BAMYA",
  "BANAK",
  "BANAL",
  "BANAZ",
  "BANDO",
  "BANJO",
  "BANKA",
  "AKONT",
  "AKORT",
  "AKPAS",
  "AKRAN",
  "AKREP",
  "AKSAK",
  "AKSAM",
  "BANKO",
  "BANMA",
  "BANYO",
  "BARAJ",
  "BARAK",
  "BARAN",
  "BARBA",
  "BARCI",
  "BARÇA",
  "BARDA",
  "AKSAN",
  "AKSON",
  "AKŞAM",
  "AKŞIN",
  "AKTAR",
  "AKTAŞ",
  "AKTİF",
  "AKTÖR",
  "AKVAM",
  "BARDO",
  "BAREM",
  "BARET",
  "BARIŞ",
  "BARİT",
  "BARİZ",
  "BARKA",
  "BAROK",
  "BARON",
  "BARUT",
  "BASAK",
  "BASAR",
  "ALACA",
  "ALAKA",
  "ALARM",
  "ALAZA",
  "ALBAY",
  "ALBÜM",
  "ALÇAK",
  "ALENİ",
  "ALEVİ",
  "BASEN",
  "BASIK",
  "BASIM",
  "BASIN",
  "BASIŞ",
  "BASİL",
  "BASİT",
  "BASKI",
  "BASMA",
  "ALEYH",
  "ALGIN",
  "ALICI",
  "ALİZE",
  "ALKAN",
  "ALKIM",
  "ALKIŞ",
  "ALKİL",
  "BASSO",
  "BASTI",
  "BASUR",
  "BASYA",
  "BAŞAK",
  "BAŞAT",
  "BAŞÇI",
  "BAŞKA",
  "BAŞLI",
  "FACİA",
  "FAGOT",
  "FAHİŞ",
  "FAHRİ",
  "FAHTE",
  "FAHUR",
  "ALKOL",
  "ALLAH",
  "ALLEM",
  "ALLIK",
  "ALMAÇ",
  "ALMAK",
  "BAŞTA",
  "BATAK",
  "BATAR",
  "BATIK",
  "BATIL",
  "BATIN",
  "BATIŞ",
  "BATİK",
  "FAKAT",
  "FAKİH",
  "FAKİR",
  "FAKÜL",
  "FALAN",
  "FALCI",
  "FALEZ",
  "FALSO",
  "FALYA",
  "FANTA",
  "FANTİ",
  "FANUS",
  "ALMAN",
  "ALMAŞ",
  "ALMUS",
  "ALNAÇ",
  "ALTES",
  "ALTIK",
  "ALTIN",
  "ALTIZ",
  "ALTLI",
  "BATKI",
  "BATMA",
  "BATON",
  "BATUR",
  "FANYA",
  "FARAD",
  "FARAŞ",
  "FARBA",
  "FARİĞ",
  "FARİL",
  "FASET",
  "ALYAN",
  "ALYON",
  "AMADE",
  "AMBAR",
  "AMBER",
  "AMELE",
  "AMELİ",
  "AMİGO",
  "AMORF",
  "AMPER",
  "BAVCI",
  "BAVLI",
  "BAVUL",
  "BAYAN",
  "BAYAT",
  "BAYIR",
  "BAYMA",
  "BAYRI",
  "BAZAL",
  "FASIK",
  "FASIL",
  "FASİH",
  "FASİT",
  "FASKA",
  "FASLI",
  "FASON",
  "FATİH",
  "FATSA",
  "FAUNA",
  "FAYDA",
  "AMPİR",
  "AMPUL",
  "AMUDİ",
  "ANACA",
  "ANALI",
  "ANANE",
  "ANCAK",
  "ANDAÇ",
  "ANDIÇ",
  "ANDIK",
  "ANDIZ",
  "BAZEN",
  "BAZİK",
  "BAZİT",
  "BEBEK",
  "BECET",
  "BEDEL",
  "BEDEN",
  "BEDİİ",
  "BEDİK",
  "BEDİR",
  "FAZIL",
  "FAZLA",
  "FECİR",
  "FEDAİ",
  "FEHİM",
  "FEHVA",
  "FEKÜL",
  "FELAH",
  "FELEK",
  "FENCİ",
  "FENER",
  "FENİK",
  "ANELE",
  "ANEMİ",
  "ANGIÇ",
  "BEDÜK",
  "BEGÜM",
  "BEHER",
  "BEHEY",
  "BEHRE",
  "BEKAR",
  "BEKAS",
  "BEKÇİ",
  "FENNİ",
  "FENOL",
  "FERAĞ",
  "FERAH",
  "FERDA",
  "FERDİ",
  "FERİH",
  "FERİK",
  "ANGIN",
  "ANGUT",
  "ANİDE",
  "ANİME",
  "ANJİN",
  "ANKET",
  "ANLAK",
  "ANLAM",
  "ANLIK",
  "ANMAK",
  "BEKRİ",
  "BELCE",
  "BELDE",
  "BELEK",
  "BELEN",
  "BELEŞ",
  "BELGE",
  "BELGİ",
  "BELİĞ",
  "BELİK",
  "FERLİ",
  "FERMA",
  "FESAT",
  "FESİH",
  "FETHA",
  "FETİH",
  "FETİŞ",
  "FETÜS",
  "FETVA",
  "FEVRİ",
  "ANONS",
  "ANSIZ",
  "ANTEN",
  "ANTET",
  "ANTİK",
  "ANTLI",
  "ANTRE",
  "BELİT",
  "BELKİ",
  "BELLİ",
  "BEMOL",
  "BENCE",
  "BENCİ",
  "BENDE",
  "BENEK",
  "BENGİ",
  "BENİZ",
  "BENLİ",
  "BERAT",
  "FEYİZ",
  "FIKIH",
  "FIKRA",
  "FIRÇA",
  "FIRIN",
  "FIRKA",
  "FIŞKI",
  "FITIK",
  "FITRİ",
  "ANÜRİ",
  "ANYON",
  "ANZAK",
  "APACI",
  "APİKO",
  "APLİK",
  "APORT",
  "BERİL",
  "BERRİ",
  "BESİN",
  "BESNİ",
  "BESTE",
  "BEŞER",
  "BEŞİK",
  "BEŞİZ",
  "FİBER",
  "FİDAN",
  "FİDYE",
  "FİFRE",
  "FİGAN",
  "FİGÜR",
  "FİİLİ",
  "FİKİR",
  "FİKRİ",
  "FİLAN",
  "EBCET",
  "EBEDİ",
  "EBELİ",
  "EBLEH",
  "ECDAT",
  "EÇHEL",
  "APOŞİ",
  "APOTR",
  "APRİL",
  "APSİS",
  "APTAL",
  "ARABA",
  "ARABİ",
  "BEŞLİ",
  "BEŞME",
  "BEŞON",
  "BEŞUŞ",
  "BETER",
  "BETİK",
  "BETİM",
  "BETON",
  "BEYAN",
  "FİLAR",
  "FİLET",
  "FİLİZ",
  "FİLOZ",
  "FİLSİ",
  "FİLUM",
  "FİNAL",
  "EDALI",
  "EDEBİ",
  "EDİNÇ",
  "EDVAR",
  "EFDAL",
  "EFECE",
  "EFEKT",
  "EFKAR",
  "EFLAK",
  "EFRAT",
  "EFRİZ",
  "EFSUN",
  "ARACI",
  "ARAKA",
  "ARALI",
  "ARAMA",
  "ARAMİ",
  "ARAZİ",
  "ARDAK",
  "ARDIÇ",
  "ARDIL",
  "ARENA",
  "BEYAZ",
  "BEYİN",
  "BEYİT",
  "BEYZİ",
  "BEZCİ",
  "BEZEK",
  "BEZEN",
  "BEZGİ",
  "FİNCE",
  "FİNİŞ",
  "FİRAK",
  "FİRAR",
  "FİREZ",
  "FİRİK",
  "FİRMA",
  "FİSKE",
  "FİSTO",
  "EFSUS",
  "EGALE",
  "EGLOG",
  "EGZOZ",
  "EĞLEK",
  "EĞMEÇ",
  "EĞMEK",
  "EĞMÜR",
  "EĞREZ",
  "EĞRİM",
  "EHRAM",
  "EHVEN",
  "ARGAÇ",
  "ARGIN",
  "ARGIT",
  "ARGON",
  "ARICI",
  "ARIZA",
  "ARIZİ",
  "BEZİK",
  "BEZİR",
  "BEZİŞ",
  "BEZME",
  "BEZSİ",
  "BICIL",
  "FİŞEK",
  "FİŞKA",
  "FİŞLİ",
  "FİTÇİ",
  "FİTİL",
  "FİTİN",
  "FİTNE",
  "FİTRE",
  "FİYAT",
  "FİZİK",
  "FLAMA",
  "FLEOL",
  "EJDER",
  "EKİCİ",
  "EKİLİ",
  "EKLEM",
  "EKLER",
  "EKMEK",
  "EKOSE",
  "EKRAN",
  "EKSEN",
  "ARİFE",
  "ARİYA",
  "ARİZA",
  "ARKAÇ",
  "ARKIT",
  "ARKOZ",
  "ARMUT",
  "ARMUZ",
  "BICIR",
  "BIÇAK",
  "BIÇIK",
  "BIÇKI",
  "BIDIK",
  "FLORA",
  "FLORİ",
  "FLÖRE",
  "FLÖRT",
  "FODLA",
  "FODRA",
  "FODUL",
  "EKSER",
  "EKSİK",
  "EKSİN",
  "EKSİZ",
  "EKÜRİ",
  "ELBET",
  "ELCİK",
  "ELÇEK",
  "ELÇİM",
  "AROMA",
  "ARPÇI",
  "ARPEJ",
  "ARSIZ",
  "ARSİN",
  "ARŞIN",
  "ARŞİV",
  "ARTÇI",
  "ARTER",
  "BIKIŞ",
  "BIKMA",
  "FOKUS",
  "FOLYO",
  "FONDA",
  "FONDÜ",
  "FONEM",
  "FORMA",
  "FOROZ",
  "FORSA",
  "FORTE",
  "FORUM",
  "FOSİL",
  "FRANK",
  "ELDEN",
  "ELEJİ",
  "ARTIK",
  "ARTIM",
  "ARTIN",
  "ARTIŞ",
  "ARTMA",
  "ASABİ",
  "ASHAP",
  "ASILI",
  "ASİDE",
  "ASİST",
  "BIYIK",
  "BIZIR",
  "BİBER",
  "BİBLO",
  "BİCİK",
  "BİÇEM",
  "BİÇİM",
  "BİÇİŞ",
  "FRAPE",
  "FRENK",
  "FRESK",
  "FREZE",
  "FRİGO",
  "ELEME",
  "ELGİN",
  "ELHAK",
  "ELİFİ",
  "ELİPS",
  "ELLİK",
  "ELMAS",
  "ELMEK",
  "ELVAN",
  "ELYAF",
  "ELZEM",
  "EMARE",
  "ASKAT",
  "ASKER",
  "ASKLI",
  "ASLAN",
  "ASLEN",
  "ASLIK",
  "ASMAK",
  "ASPUR",
  "ASTAR",
  "ASTAT",
  "ASTIM",
  "BİÇKİ",
  "BİÇME",
  "FRİSA",
  "FUAYE",
  "FUHUŞ",
  "FUJER",
  "FULAR",
  "FULYA",
  "FUNDA",
  "FURYA",
  "FÜLÜS",
  "FÜNYE",
  "FÜSUN",
  "EMAYE",
  "EMCEK",
  "EMCİK",
  "EMİCİ",
  "EMLAK",
  "EMLİK",
  "EMMEÇ",
  "EMMEK",
  "EMRAZ",
  "EMSAL",
  "EMTİA",
  "DADAŞ",
  "DADAY",
  "DAĞAR",
  "DAĞCI",
  "DAĞLI",
  "DAHİL",
  "DAİMA",
  "ASTİK",
  "ASUDE",
  "ASYÖN",
  "AŞAĞI",
  "AŞAMA",
  "AŞARİ",
  "AŞEVİ",
  "AŞICI",
  "AŞILI",
  "AŞIRI",
  "BİDAR",
  "BİDAT",
  "BİDON",
  "BİHUŞ",
  "BİKES",
  "BİKİR",
  "FÜTUR",
  "FÜZEN",
  "EMVAL",
  "EMZİK",
  "ENAYİ",
  "ENCAM",
  "ENDAM",
  "ENDER",
  "ENEME",
  "ENEZE",
  "ENFES",
  "ENGEL",
  "ENGİN",
  "DAİMİ",
  "DAİRE",
  "DAKİK",
  "DALAK",
  "DALAN",
  "DALAŞ",
  "DALGA",
  "DALGI",
  "DALIŞ",
  "DALIZ",
  "DALLI",
  "DALMA",
  "AŞİNA",
  "AŞKIN",
  "AŞLIK",
  "AŞMAK",
  "AŞURE",
  "ATAMA",
  "ATARİ",
  "ATAŞE",
  "ATFEN",
  "ATICI",
  "ATILI",
  "ATİNA",
  "BİLAR",
  "BİLEK",
  "BİLET",
  "BİLGE",
  "BİLGİ",
  "BİLİM",
  "BİLİR",
  "BİLİŞ",
  "BİLME",
  "BİLYE",
  "BİNDİ",
  "ENKAZ",
  "ENLEM",
  "ENÖTE",
  "ENSAR",
  "ENSİZ",
  "ENTEL",
  "ENZİM",
  "EOSEN",
  "EPEYİ",
  "EPOPE",
  "EPSEM",
  "ERBAA",
  "DALSI",
  "DALYA",
  "DAMAK",
  "DAMAL",
  "DAMAR",
  "DAMAT",
  "DAMGA",
  "DAMLA",
  "DAMLI",
  "DANCA",
  "DANIŞ",
  "ATLAS",
  "ATLET",
  "ATMAK",
  "ATMIK",
  "AVANE",
  "AVANS",
  "AVARA",
  "AVARE",
  "AVAZE",
  "AVDET",
  "BİNEK",
  "BİNER",
  "BİNGİ",
  "BİNİŞ",
  "BİNİT",
  "BİNME",
  "ERBAP",
  "ERBAŞ",
  "ERBİN",
  "ERCİK",
  "ERCİŞ",
  "ERDEK",
  "ERDEM",
  "ERDEN",
  "ERGEN",
  "ERGİN",
  "ERİKA",
  "ERİME",
  "DARAÇ",
  "DARAŞ",
  "DARBE",
  "DARCA",
  "DASİT",
  "DATÇA",
  "DATİF",
  "DAVAR",
  "DAVET",
  "DAVUL",
  "DAVYA",
  "AVİZE",
  "AVLAK",
  "AVRAT",
  "AVRET",
  "AVŞAR",
  "AVUNÇ",
  "AVURT",
  "AYDIN",
  "AYEVİ",
  "BİRAZ",
  "BİRCİ",
  "BİRER",
  "BİREY",
  "BİRİM",
  "BİRLİ",
  "BİRUN",
  "BİŞEK",
  "BİTAP",
  "BİTEK",
  "BİTEY",
  "ERİNÇ",
  "ERKAN",
  "DAYAK",
  "DEBBE",
  "DEBİL",
  "DEFİN",
  "DEFNE",
  "DEGAJ",
  "DEĞEÇ",
  "DEĞER",
  "DEĞİL",
  "DEĞİM",
  "DEĞİN",
  "AYGIR",
  "AYGIT",
  "AYICI",
  "AYIRT",
  "AYLAK",
  "AYLIK",
  "AYMAK",
  "AYMAZ",
  "AYNAZ",
  "AYNEN",
  "BİTİK",
  "BİTİM",
  "BİTİŞ",
  "BİTKİ",
  "BİTLİ",
  "BİTME",
  "BİTÜM",
  "BİYEL",
  "BİZAR",
  "BİZCE",
  "BİZON",
  "BLOKE",
  "ERKEÇ",
  "ERKEK",
  "ERKEN",
  "ERKİN",
  "ERKLİ",
  "ERLİK",
  "ERMEK",
  "ERMİN",
  "ERMİŞ",
  "DEĞİŞ",
  "DEĞME",
  "DEİST",
  "DEİZM",
  "DEKAN",
  "DEKAR",
  "DEKOR",
  "DELGİ",
  "DELİK",
  "DELİL",
  "DELME",
  "DELTA",
  "AYRAÇ",
  "AYRAN",
  "AYRIÇ",
  "AYRIK",
  "AYRIM",
  "AYRIT",
  "AYSAR",
  "AYSIZ",
  "BOBİN",
  "BOCCE",
  "BOCUK",
  "BODUÇ",
  "BODUR",
  "BOĞAK",
  "BOĞAZ",
  "BOĞMA",
  "EROİN",
  "ERSİZ",
  "ERVAH",
  "ERZAK",
  "DEMCİ",
  "DEMEÇ",
  "DEMEK",
  "DEMET",
  "DEMİN",
  "DEMİR",
  "DEMLİ",
  "DEMRE",
  "DENEK",
  "DENET",
  "DENEY",
  "DENGE",
  "AYVAN",
  "AYVAZ",
  "AYYAR",
  "AYYAŞ",
  "AYYUK",
  "AZADE",
  "AZAMİ",
  "AZERİ",
  "AZGIN",
  "BOĞUK",
  "BOĞUM",
  "BOHÇA",
  "BOHEM",
  "BOKLU",
  "BOLCA",
  "BOMBA",
  "BOMBE",
  "BORAK",
  "BORAN",
  "ERZEL",
  "ERZİN",
  "ESAME",
  "ESANS",
  "ESASİ",
  "ESBAK",
  "ESBAP",
  "ESEME",
  "ESHAM",
  "ESİRE",
  "ESKİL",
  "DENİM",
  "DENİZ",
  "DENLİ",
  "DENME",
  "DENYO",
  "DEPAR",
  "DERBİ",
  "DERGİ",
  "DERİK",
  "DERİN",
  "DERME",
  "DERUN",
  "DERYA",
  "AZILI",
  "AZİZE",
  "AZLIK",
  "AZMAK",
  "AZMAN",
  "AZNİF",
  "AZOİK",
  "AZVAY",
  "BORAT",
  "BORDA",
  "ESKİZ",
  "ESLAF",
  "ESLEK",
  "ESMEK",
  "ESMER",
  "ESNAF",
  "ESNEK",
  "DESEN",
  "DESTE",
  "DEŞİK",
  "DEŞME",
  "DETAY",
  "BORDO",
  "BORİK",
  "BORSA",
  "BORUK",
  "BOYAR",
  "BOYCA",
  "BOYLU",
  "BOYNA",
  "ESPAS",
  "ESPRİ",
  "ESRAR",
  "ESRİK",
  "ESSAH",
  "ESTER",
  "DEVAM",
  "DEVCE",
  "DEVİM",
  "DEVİR",
  "DEVRE",
  "DEVRİ",
  "DEYİM",
  "DEYİŞ",
  "DIĞAN",
  "DILAK",
  "DIŞIK",
  "DIŞKI",
  "GABİN",
  "GABRO",
  "GABYA",
  "GADİR",
  "GAFİL",
  "GAFUR",
  "BOYOZ",
  "BOYUN",
  "BOYUT",
  "BOZCA",
  "BOZMA",
  "BOZUK",
  "BOZUM",
  "BOZUŞ",
  "BÖBÜR",
  "BÖCEK",
  "BÖĞÜR",
  "BÖLEN",
  "ESTET",
  "ESVAP",
  "EŞARP",
  "EŞHAS",
  "EŞKAL",
  "EŞKİN",
  "EŞLEK",
  "EŞLEM",
  "DİBEK",
  "DİCLE",
  "DİDAR",
  "DİDİM",
  "GAİLE",
  "GAİTA",
  "GALAT",
  "GALİP",
  "GALİZ",
  "GALON",
  "GALOP",
  "GALOŞ",
  "GAMBA",
  "GAMET",
  "GAMLI",
  "GAMZE",
  "BÖLGE",
  "BÖLME",
  "BÖLÜK",
  "BÖLÜM",
  "BÖLÜŞ",
  "BÖLÜT",
  "BÖNCE",
  "BÖREK",
  "BÖYLE",
  "EŞLİK",
  "EŞMEK",
  "EŞRAF",
  "EŞREF",
  "EŞSİZ",
  "DİDON",
  "DİGOR",
  "DİĞER",
  "DİKÇE",
  "DİKEÇ",
  "DİKEL",
  "DİKEN",
  "DİKEY",
  "DİKİM",
  "DİKİŞ",
  "DİKİT",
  "GARAJ",
  "GARAZ",
  "GARBİ",
  "GARİP",
  "GAROZ",
  "BRANŞ",
  "BRAVO",
  "BRONŞ",
  "BRONZ",
  "BRÖVE",
  "BUCAK",
  "BUÇUK",
  "BUDAK",
  "BUDUN",
  "BUGÜN",
  "ETÇİK",
  "ETÇİL",
  "ETENE",
  "ETFAL",
  "ETKEN",
  "ETKİN",
  "ETLİK",
  "DİKİZ",
  "DİKME",
  "DİKSE",
  "DİKTA",
  "DİKTE",
  "DİLCE",
  "DİLCİ",
  "DİLEK",
  "GASİL",
  "GAŞİY",
  "GAUSS",
  "GAVOT",
  "GAVUR",
  "GAYDA",
  "BUĞRA",
  "BUĞUR",
  "BUĞUZ",
  "BUHAR",
  "BUHUR",
  "BUKET",
  "BUKLE",
  "ETMEK",
  "ETMEN",
  "ETNİK",
  "ETRAF",
  "ETSEL",
  "ETSİZ",
  "EVAZE",
  "EVCEK",
  "EVCİK",
  "EVCİL",
  "DİLİM",
  "DİLİŞ",
  "DİLLİ",
  "DİLME",
  "DİLSİ",
  "DİMAĞ",
  "DİNAR",
  "DİNCE",
  "DİNCİ",
  "GAYET",
  "GAYRI",
  "GAYRİ",
  "GAYUR",
  "GAYYA",
  "GAZAL",
  "GAZAP",
  "GAZEL",
  "GAZLI",
  "GAZOZ",
  "GAZVE",
  "GEBEŞ",
  "BULAK",
  "BULAŞ",
  "BULGU",
  "BULMA",
  "BULUŞ",
  "BULUT",
  "BUNAK",
  "BUNCA",
  "BUNLU",
  "EVDEŞ",
  "EVGİN",
  "EVHAM",
  "EVİYE",
  "DİNEK",
  "DİNEN",
  "DİNGİ",
  "DİNGO",
  "DİNİŞ",
  "DİNLİ",
  "DİNME",
  "GEBRE",
  "GEBZE",
  "GEÇÇE",
  "GEÇEK",
  "GEÇEN",
  "GEÇER",
  "GEÇİM",
  "GEÇİŞ",
  "GEÇİT",
  "GEÇME",
  "GEDİK",
  "GEDİZ",
  "BUNMA",
  "BURAK",
  "BURCU",
  "BURGU",
  "BURMA",
  "BURSA",
  "BURUK",
  "BURUN",
  "EVKAF",
  "EVLAT",
  "EVLEK",
  "EVLİK",
  "EVRAK",
  "EVRAT",
  "EVREN",
  "EVRİK",
  "DİPLİ",
  "DİREK",
  "DİREN",
  "DİREY",
  "DİRİĞ",
  "DİRİL",
  "DİRİM",
  "DİSKO",
  "GEDME",
  "GELEN",
  "GELİN",
  "GELİR",
  "GELİŞ",
  "GELME",
  "GEMRE",
  "BUŞON",
  "BUTİK",
  "BUTLU",
  "BUTON",
  "BUYMA",
  "BUYOT",
  "BUZCU",
  "BUZLA",
  "BUZLU",
  "BUZUL",
  "EVRİM",
  "EVSAF",
  "EVSİN",
  "EVSİZ",
  "EVVEL",
  "EYLEM",
  "EYLÜL",
  "EYTAM",
  "EYVAH",
  "DİŞÇİ",
  "DİŞİL",
  "DİŞLİ",
  "DİTME",
  "DİVAL",
  "DİVAN",
  "DİVİK",
  "DİVİT",
  "DİYAR",
  "GENEL",
  "GENİŞ",
  "GENİZ",
  "GENOM",
  "GEOİT",
  "GERÇİ",
  "BÜCÜR",
  "BÜĞET",
  "BÜĞLÜ",
  "BÜKEN",
  "BÜKME",
  "BÜKÜÇ",
  "BÜKÜK",
  "BÜKÜM",
  "BÜKÜN",
  "BÜKÜŞ",
  "BÜLUĞ",
  "BÜNYE",
  "EYVAN",
  "EYYAM",
  "EZANİ",
  "EZBER",
  "EZELİ",
  "EZGİÇ",
  "EZGİN",
  "EZİCİ",
  "EZİNÇ",
  "EZİNE",
  "DİYET",
  "DİYEZ",
  "DİYOT",
  "DİZEK",
  "DİZEL",
  "DİZEM",
  "DİZEY",
  "DİZGE",
  "DİZGİ",
  "GEREÇ",
  "GEREK",
  "GEREN",
  "GERGİ",
  "GERİM",
  "GERİŞ",
  "GERİZ",
  "GERME",
  "GERZE",
  "GETTO",
  "BÜRGÜ",
  "BÜRÜK",
  "BÜRÜN",
  "BÜTAN",
  "BÜTÇE",
  "BÜTEN",
  "BÜTEY",
  "EZMEK",
  "DİZİM",
  "DİZİN",
  "DİZİŞ",
  "DİZME",
  "DOBRA",
  "DOGMA",
  "DOĞAÇ",
  "DOĞAL",
  "GEVAŞ",
  "GEVEN",
  "GEVİŞ",
  "GEVME",
  "GEYİK",
  "GEYŞA",
  "GEYVE",
  "GEZİŞ",
  "GEZME",
  "BÜTÜN",
  "BÜVET",
  "BÜYÜK",
  "BÜZGÜ",
  "BÜZME",
  "BÜZÜK",
  "DOĞAN",
  "DOĞMA",
  "DOĞRU",
  "DOĞUM",
  "DOĞUŞ",
  "DOKUZ",
  "DOLAK",
  "DOLAM",
  "DOLAP",
  "DOLAR",
  "GICIK",
  "GICIR",
  "GIDIK",
  "CACIK",
  "CADDE",
  "CAHİL",
  "CAİZE",
  "CALİP",
  "CAMCI",
  "CAMIZ",
  "CAMİA",
  "DOLAY",
  "DOLGU",
  "DOLMA",
  "DOLUM",
  "DOLUŞ",
  "DOMUR",
  "DOMUZ",
  "DONAM",
  "DONLU",
  "DONMA",
  "GIDIM",
  "GIPTA",
  "GIRLA",
  "GIYAP",
  "GİDER",
  "GİDİŞ",
  "GİDON",
  "GİRAY",
  "GİRDİ",
  "GİREN",
  "CAMİT",
  "CAMLI",
  "CAMSI",
  "DONÖR",
  "DONRA",
  "DONUK",
  "DORSE",
  "DORUK",
  "DORUM",
  "DOSYA",
  "DOYGU",
  "DOYMA",
  "DOYUM",
  "DOYUŞ",
  "DOZAJ",
  "GİRİM",
  "GİRİŞ",
  "GİRME",
  "GİTAR",
  "GİTME",
  "GİYİM",
  "CANAN",
  "CANIM",
  "CANİK",
  "CANİP",
  "CANLI",
  "CARLI",
  "CARTA",
  "CASUS",
  "CAYIŞ",
  "DOZER",
  "DÖGER",
  "DÖKME",
  "DÖKÜK",
  "DÖKÜM",
  "DÖKÜŞ",
  "DÖLEK",
  "DÖLÜT",
  "GİYİŞ",
  "GİYİT",
  "GİYME",
  "GİYSİ",
  "GİZEM",
  "GİZİL",
  "GİZLİ",
  "GLASE",
  "GNAYS",
  "CAYMA",
  "CAZCI",
  "CAZİP",
  "CAZLI",
  "CEBEL",
  "CEBİN",
  "CEBİR",
  "CEBRİ",
  "CEDEL",
  "CEDİT",
  "DÖNEÇ",
  "DÖNEK",
  "DÖNEL",
  "DÖNEM",
  "DÖNER",
  "DÖNGÜ",
  "DÖNME",
  "DÖNÜK",
  "DÖNÜM",
  "GOCUK",
  "GODOŞ",
  "GOLCÜ",
  "GOLLÜ",
  "GONCA",
  "GORİL",
  "GOTÇA",
  "CEDRE",
  "CEHİL",
  "CEHRE",
  "CEHRİ",
  "CEKET",
  "CELAL",
  "CELBE",
  "CELEP",
  "CELİL",
  "CELSE",
  "CEMAL",
  "DÖNÜŞ",
  "DÖNÜT",
  "DÖPER",
  "DÖŞEK",
  "DÖŞEM",
  "GOTİK",
  "GÖBEK",
  "GÖBEL",
  "GÖBÜT",
  "GÖCEN",
  "GÖÇER",
  "GÖÇME",
  "GÖÇÜK",
  "CEMAN",
  "CEMİL",
  "CEMRE",
  "CENAH",
  "CENAP",
  "CENİN",
  "CENUP",
  "CEPÇİ",
  "DÖŞLÜ",
  "DÖVEÇ",
  "DÖVİZ",
  "DÖVME",
  "DÖVÜŞ",
  "DRAJE",
  "DRAMA",
  "GÖÇÜM",
  "GÖÇÜŞ",
  "GÖDEN",
  "GÖDEŞ",
  "GÖĞEM",
  "GÖĞÜS",
  "GÖKÇE",
  "CEPHE",
  "CEREN",
  "CESET",
  "CESİM",
  "CESUR",
  "CEVAP",
  "CEVAZ",
  "CEVİR",
  "DUACI",
  "DUALI",
  "DUBAR",
  "DUBLE",
  "DUDAK",
  "DUHUL",
  "DULDA",
  "DULUK",
  "DUMAN",
  "GÖLEK",
  "GÖLET",
  "GÖLGE",
  "GÖLÜK",
  "CEVİZ",
  "CEVVİ",
  "CEVZA",
  "CEZAİ",
  "CEZBE",
  "CEZİR",
  "ÇABUK",
  "ÇADIR",
  "ÇAĞLA",
  "ÇAĞMA",
  "ÇAĞRI",
  "ÇAKAL",
  "ÇAKAR",
  "ÇAKER",
  "DUMUR",
  "DURAÇ",
  "DURAK",
  "DURAL",
  "DURGU",
  "DURMA",
  "GÖMEÇ",
  "GÖMME",
  "GÖMÜK",
  "GÖMÜŞ",
  "GÖMÜT",
  "GÖNCÜ",
  "GÖNEN",
  "CEZRİ",
  "CEZVE",
  "CIBIL",
  "CICIK",
  "CILIZ",
  "CIVIK",
  "CIVMA",
  "ÇAKIL",
  "ÇAKIM",
  "ÇAKIN",
  "ÇAKIR",
  "ÇAKIŞ",
  "ÇAKMA",
  "DURUK",
  "DURUM",
  "DURUŞ",
  "DUŞAK",
  "DUŞLU",
  "DUTÇU",
  "DUVAK",
  "GÖNÜL",
  "GÖNYE",
  "GÖREV",
  "GÖRGÜ",
  "GÖRME",
  "GÖRÜM",
  "GÖRÜŞ",
  "GÖVDE",
  "CIZIK",
  "CİBİN",
  "CİBRE",
  "CİCİK",
  "CİCİM",
  "CİCOZ",
  "CİDAL",
  "ÇAKRA",
  "ÇALAK",
  "ÇALAP",
  "ÇALAR",
  "ÇALGI",
  "ÇALIK",
  "ÇALIM",
  "DUVAR",
  "GÖVEK",
  "GÖVEL",
  "GÖVEM",
  "GÖYME",
  "GÖYÜK",
  "GÖZCÜ",
  "GÖZDE",
  "CİDAR",
  "CİDDİ",
  "CİĞER",
  "CİHAN",
  "CİHAR",
  "CİHAT",
  "CİHAZ",
  "ÇALIŞ",
  "ÇALKI",
  "ÇALMA",
  "ÇALTI",
  "ÇAMAŞ",
  "ÇAMAT",
  "DUYAR",
  "DUYGU",
  "DUYMA",
  "DUYUM",
  "DUYUŞ",
  "DÜBEL",
  "DÜBEŞ",
  "DÜÇAR",
  "DÜDEN",
  "GÖZER",
  "GÖZGÜ",
  "GÖZLÜ",
  "GRADO",
  "GRENA",
  "GRİZU",
  "GROGİ",
  "GROSA",
  "GUANO",
  "GUATR",
  "CİHET",
  "CİLVE",
  "CİMRİ",
  "CİNAİ",
  "CİNAS",
  "CİNCİ",
  "CİNLİ",
  "CİNSİ",
  "CİRİM",
  "CİRİT",
  "CİSİM",
  "ÇAMÇA",
  "ÇAMUR",
  "ÇANAK",
  "ÇANCI",
  "ÇANDI",
  "ÇANLI",
  "ÇANTA",
  "ÇAPAK",
  "DÜDÜK",
  "DÜGAH",
  "DÜĞME",
  "DÜĞÜM",
  "DÜĞÜN",
  "DÜMEN",
  "DÜNİT",
  "DÜNKÜ",
  "GUDDE",
  "GUGUK",
  "GULAŞ",
  "GULET",
  "GURME",
  "GURUP",
  "GURUR",
  "GUSTO",
  "CİVAN",
  "CİVAR",
  "CİZRE",
  "CİZYE",
  "CONTA",
  "CORUM",
  "COŞKU",
  "COŞMA",
  "COŞUM",
  "ÇAPAR",
  "ÇAPLA",
  "ÇAPLI",
  "ÇAPMA",
  "ÇAPUL",
  "ÇAPUT",
  "ÇARIK",
  "ÇARKA",
  "ÇARPI",
  "ÇARŞI",
  "DÜNÜR",
  "DÜNYA",
  "DÜRME",
  "DÜRTÜ",
  "DÜRÜM",
  "DÜRZİ",
  "DÜRZÜ",
  "DÜŞÇÜ",
  "DÜŞES",
  "GUSÜL",
  "GÜBRE",
  "GÜBÜR",
  "GÜCÜK",
  "GÜCÜN",
  "GÜÇLÜ",
  "GÜDEK",
  "GÜDÜK",
  "COŞUŞ",
  "CUDAM",
  "CUKKA",
  "CUMBA",
  "CUNDA",
  "CUNTA",
  "CUŞİŞ",
  "CÜBBE",
  "CÜCÜK",
  "ÇASAR",
  "ÇAŞIT",
  "ÇATAK",
  "ÇATAL",
  "ÇATIK",
  "ÇATIŞ",
  "ÇATKI",
  "ÇATMA",
  "ÇAVLI",
  "ÇAVMA",
  "DÜŞEŞ",
  "DÜŞEY",
  "DÜŞKÜ",
  "DÜŞME",
  "DÜŞÜK",
  "DÜŞÜN",
  "DÜŞÜŞ",
  "DÜŞÜT",
  "DÜVEL",
  "DÜVEN",
  "GÜDÜL",
  "GÜDÜM",
  "GÜFTE",
  "GÜĞÜM",
  "GÜLCÜ",
  "GÜLEÇ",
  "GÜLLE",
  "GÜLLÜ",
  "GÜLME",
  "GÜLÜK",
  "CÜLUS",
  "CÜMLE",
  "CÜNHA",
  "CÜNUN",
  "CÜNÜP",
  "CÜRET",
  "CÜRUF",
  "CÜRÜM",
  "CÜSSE",
  "ÇAVUN",
  "ÇAVUŞ",
  "ÇAYAN",
  "ÇAYCI",
  "ÇAYIR",
  "ÇAYLI",
  "ÇEBİÇ",
  "ÇECİK",
  "ÇEÇEN",
  "DÜVER",
  "DÜYEK",
  "DÜYUN",
  "DÜZCE",
  "DÜZEÇ",
  "DÜZEM",
  "DÜZEN",
  "DÜZEY",
  "DÜZGÜ",
  "GÜLÜŞ",
  "GÜLÜT",
  "GÜMEÇ",
  "GÜMÜL",
  "GÜMÜŞ",
  "GÜNAH",
  "GÜNCE",
  "GÜNDE",
  "GÜNEÇ",
  "GÜNEŞ",
  "GÜNEY",
  "ÇEDİK",
  "ÇEHRE",
  "ÇEKÇE",
  "ÇEKEK",
  "ÇEKEL",
  "ÇEKEM",
  "ÇEKER",
  "ÇEKİÇ",
  "ÇEKİK",
  "ÇEKİM",
  "ÇEKİŞ",
  "DÜZME",
  "GÜNLÜ",
  "GÜPÜR",
  "GÜRCÜ",
  "GÜREŞ",
  "GÜRSU",
  "GÜRUH",
  "GÜRÜN",
  "GÜTME",
  "GÜVEÇ",
  "GÜVEN",
  "ÇEKME",
  "ÇEKÜL",
  "ÇELEK",
  "ÇELEN",
  "ÇELGİ",
  "ÇELİK",
  "ÇELİM",
  "ÇELLO",
  "ÇELME",
  "GÜVEZ",
  "GÜZEL",
  "GÜZEY",
  "GÜZÜN",
  "ÇEMÇE",
  "ÇEMEN",
  "ÇEMİÇ",
  "ÇEMİŞ",
  "ÇENEK",
  "ÇENET",
  "ÇENGİ",
  "ÇEPEL",
  "ÇEPER",
  "ÇEPEZ",
  "ÇEPİN",
  "ÇEPNİ",
  "ÇERAĞ",
  "ÇERÇİ",
  "ÇEREZ",
  "ÇERGE",
  "ÇEŞİT",
  "ÇEŞME",
  "ÇEŞNİ",
  "ÇETİN",
  "ÇEVİK",
  "ÇEVRE",
  "ÇEVRİ",
  "ÇEYİZ",
  "ÇIBAN",
  "ÇIDAM",
  "ÇIFIT",
  "ÇIĞIR",
  "ÇIKAK",
  "ÇIKAN",
  "ÇIKAR",
  "ÇIKIK",
  "ÇIKIN",
  "ÇIKIŞ",
  "ÇIKIT",
  "ÇIKMA",
  "ÇIKRA",
  "ÇIKTI",
  "ÇINAR",
  "ÇINGI",
  "ÇIPIR",
  "ÇIRAĞ",
  "ÇIRAK",
  "ÇIRPI",
  "ÇITAK",
  "ÇITIR",
  "ÇIVMA",
  "ÇIYAN",
  "ÇİÇEK",
  "ÇİFTE",
  "ÇİGAN",
  "ÇİĞDE",
  "ÇİĞİL",
  "ÇİĞİN",
  "ÇİĞİT",
  "ÇİĞLİ",
  "ÇİLEK",
  "ÇİLLİ",
  "ÇİMEK",
  "ÇİMEN",
  "ÇİMME",
  "ÇİNCE",
  "ÇİNKO",
  "ÇİNLİ",
  "ÇİPİL",
  "ÇİRİŞ",
  "ÇİROZ",
  "ÇİSEN",
  "ÇİŞİK",
  "ÇİTEN",
  "ÇİTME",
  "ÇİVİT",
  "ÇİZER",
  "ÇİZGE",
  "ÇİZGİ",
  "ÇİZİK",
  "ÇİZİM",
  "ÇİZİŞ",
  "ÇİZME",
  "ÇOBAN",
  "ÇOCUK",
  "ÇOĞUL",
  "ÇOĞUN",
  "ÇOKAL",
  "ÇOKÇA",
  "ÇOKÇU",
  "ÇOKLU",
  "ÇOLAK",
  "ÇOLPA",
  "ÇOMAK",
  "ÇOMAR",
  "ÇOPRA",
  "ÇOPUR",
  "ÇORAK",
  "ÇORAP",
  "ÇORBA",
  "ÇORLU",
  "ÇORUM",
  "ÇOTRA",
  "ÇOTUK",
  "ÇÖĞME",
  "ÇÖĞÜR",
  "ÇÖKEK",
  "ÇÖKEL",
  "ÇÖKME",
  "ÇÖKÜK",
  "ÇÖKÜM",
  "ÇÖKÜŞ",
  "ÇÖMÇE",
  "ÇÖMEZ",
  "ÇÖMME",
  "ÇÖPÇÜ",
  "ÇÖPLÜ",
  "ÇÖREK",
  "ÇÖRKÜ",
  "ÇÖRTÜ",
  "ÇÖVEN",
  "ÇÖZGÜ",
  "ÇÖZME",
  "ÇÖZÜK",
  "ÇÖZÜM",
  "ÇÖZÜŞ",
  "ÇUBUK",
  "ÇUKUR",
  "ÇULCU",
  "ÇULHA",
  "ÇUMRA",
  "ÇUPRA",
  "ÇUŞKA",
  "ÇUVAL",
  "ÇUVAŞ",
  "ÇÜKÜR",
  "ÇÜNKÜ",
  "ÇÜRÜK",
  "LAÇIN",
  "LAÇİN",
  "LAÇKA",
  "LADEN",
  "LADES",
  "LADİK",
  "LADİN",
  "LAFÇI",
  "LAFIZ",
  "LAFZİ",
  "LAGAR",
  "LAGOS",
  "LAGÜN",
  "LAĞIM",
  "LAĞIV",
  "LAHİT",
  "LAHOS",
  "LAHUT",
  "LAHZA",
  "LAKAP",
  "LAKÇI",
  "LAKİN",
  "LAKOZ",
  "LAMBA",
  "LAMEL",
  "LANDO",
  "LANET",
  "LANSE",
  "LAPON",
  "LARGO",
  "LARVA",
  "LASKİ",
  "LASTA",
  "LATİF",
  "LATİN",
  "LAVAJ",
  "LAVAŞ",
  "LAVTA",
  "LAVUK",
  "LAYIK",
  "LAZCA",
  "LAZER",
  "LAZIM",
  "LAZUT",
  "LEÇEK",
  "LEDÜN",
  "LEGAL",
  "LEĞEN",
  "LEHÇE",
  "LEHİM",
  "LEMİS",
  "LENFA",
  "LENTO",
  "LEPRA",
  "LERZE",
  "LETÇE",
  "LEVHA",
  "LEVYE",
  "LEYDİ",
  "LEYLİ",
  "LEZAR",
  "LEZİZ",
  "LIĞLI",
  "LIKIR",
  "LİBAS",
  "LİBOŞ",
  "LİBRE",
  "LİDER",
  "LİFLİ",
  "LİGER",
  "LİKEN",
  "LİKİT",
  "LİKÖR",
  "LİMAN",
  "LİMBO",
  "LİMİT",
  "LİMON",
  "LİNET",
  "LİNİN",
  "LİPİT",
  "LİPOM",
  "LİRET",
  "LİRİK",
  "LİSAN",
  "LİSTE",
  "LİTRE",
  "LİVAR",
  "LİYAN",
  "LİZOL",
  "LİZÖZ",
  "LOBUT",
  "LODOS",
  "LOGOS",
  "LOJİK",
  "LOKAL",
  "LOKMA",
  "LOKUM",
  "LONCA",
  "LONGA",
  "LOPUR",
  "LORTA",
  "LOŞÇA",
  "LOTUS",
  "KABAK",
  "KABAN",
  "KABIZ",
  "KABİL",
  "LÖKOZ",
  "LÖPÜR",
  "LÜFER",
  "LÜGAT",
  "LÜGOL",
  "LÜMEN",
  "LÜNET",
  "LÜPÇÜ",
  "KABİN",
  "KABİR",
  "KABLO",
  "KABUK",
  "KABUL",
  "KABUS",
  "KABZA",
  "KAÇAK",
  "KAÇAR",
  "KAÇIK",
  "KAÇIŞ",
  "LÜTUF",
  "LÜZUM",
  "KAÇLI",
  "KAÇMA",
  "KAÇTA",
  "KADAR",
  "KADEH",
  "KADEM",
  "KADER",
  "KADIN",
  "KADİM",
  "KADİR",
  "KADİT",
  "KADRO",
  "KADÜK",
  "KAFES",
  "KAFİR",
  "KAFUR",
  "KAGİR",
  "KAĞAN",
  "KAĞIT",
  "KAĞNI",
  "KAHIR",
  "KAHİL",
  "KAHİN",
  "KAHİR",
  "KAHPE",
  "KAHTA",
  "KAHVE",
  "KAHYA",
  "KAİDE",
  "KAİME",
  "KAKAÇ",
  "KAKAO",
  "KAKIÇ",
  "KAKIM",
  "İBARE",
  "İBATE",
  "İBDAİ",
  "İBLAĞ",
  "İBLİS",
  "İBRAZ",
  "İBRET",
  "KAKIŞ",
  "KAKMA",
  "KAKÜL",
  "KALAK",
  "KALAN",
  "KALAS",
  "KALAY",
  "KALBİ",
  "KALCI",
  "KALÇA",
  "KALEM",
  "İBRİK",
  "İBZAL",
  "İCBAR",
  "İCMAL",
  "İÇERİ",
  "İÇİCİ",
  "İÇKİN",
  "İÇLEM",
  "İÇLİK",
  "İÇMEK",
  "İÇREK",
  "IĞDIR",
  "KALFA",
  "KALIÇ",
  "KALIK",
  "KALIM",
  "KALIN",
  "KALIP",
  "KALIŞ",
  "KALIT",
  "KALMA",
  "KALYA",
  "İÇSEL",
  "İÇSİZ",
  "İÇTEN",
  "İÇYÜZ",
  "İDADİ",
  "İDAME",
  "İDARE",
  "İDARİ",
  "İDDİA",
  "İDEAL",
  "İDMAN",
  "İDRAK",
  "IĞRIP",
  "IHMAK",
  "ILGAR",
  "ILGAZ",
  "ILGIM",
  "ILGIN",
  "ILGIN",
  "ILICA",
  "ILICA",
  "ILIMA",
  "KAMAN",
  "KAMÇI",
  "KAMER",
  "KAMET",
  "KAMGA",
  "KAMIŞ",
  "KAMİL",
  "İDRAR",
  "İFADE",
  "İFFET",
  "İFHAM",
  "İFLAH",
  "İFLAS",
  "İFRAĞ",
  "İFRAT",
  "İFRAZ",
  "ILTAR",
  "IRAMA",
  "IRGAT",
  "IRKÇI",
  "IRMAK",
  "ISKAT",
  "ISLAH",
  "ISLAK",
  "ISLIK",
  "ISRAR",
  "ISSIZ",
  "ISTAR",
  "KAMUS",
  "KANAL",
  "KANAT",
  "KANCA",
  "KANIK",
  "KANIŞ",
  "KANIT",
  "KANİŞ",
  "KANKA",
  "KANLI",
  "KANMA",
  "KANON",
  "KANTO",
  "İFRİT",
  "İFSAT",
  "İFTAR",
  "İĞDİR",
  "İĞDİŞ",
  "İĞFAL",
  "İĞLİK",
  "İHALE",
  "İHATA",
  "IŞIMA",
  "IŞKIN",
  "IŞTIR",
  "ITLAK",
  "ITRAH",
  "IZGIN",
  "KANUN",
  "KAPAK",
  "KAPAN",
  "KAPIŞ",
  "KAPİK",
  "KAPİŞ",
  "KAPLI",
  "KAPMA",
  "KAPUT",
  "KAPUZ",
  "KARAR",
  "KARAY",
  "İHBAR",
  "İHDAS",
  "İHLAL",
  "İHLAS",
  "İHMAL",
  "İHRAÇ",
  "İHRAM",
  "İHRAZ",
  "İHSAN",
  "IZRAR",
  "KARGA",
  "KARGI",
  "KARGO",
  "KARHA",
  "KARIK",
  "KARIN",
  "İHSAS",
  "İHTAR",
  "İHVAN",
  "İHZAR",
  "İKAME",
  "İKBAL",
  "İKDAM",
  "KARIŞ",
  "KARLI",
  "KARMA",
  "KARNE",
  "KARNİ",
  "KARST",
  "KARŞI",
  "KARUN",
  "KARYE",
  "KASAP",
  "KASEM",
  "İKİCİ",
  "İKİLİ",
  "İKLİM",
  "İKMAL",
  "İKONA",
  "İKRAH",
  "İKRAM",
  "İKRAR",
  "KASET",
  "İKRAZ",
  "İKSİR",
  "İLAHE",
  "İLAHİ",
  "İLAHİ",
  "İLAVE",
  "İLBAY",
  "İLENÇ",
  "İLERİ",
  "İLETİ",
  "İLGEÇ",
  "KASIK",
  "KASIM",
  "KASIR",
  "KASIT",
  "KASİS",
  "KASKO",
  "KASLI",
  "KASMA",
  "KASNI",
  "KASTİ",
  "KAŞAN",
  "İLHAK",
  "İLHAM",
  "İLHAN",
  "İLKAH",
  "İLKEL",
  "İLKİN",
  "İLLET",
  "İLMEK",
  "İLMİK",
  "KAŞAR",
  "KAŞIK",
  "KAŞİF",
  "KAŞLI",
  "KATAR",
  "KATÇI",
  "KATIK",
  "KATIM",
  "KATIR",
  "İLZAM",
  "İMALE",
  "İMALI",
  "İMAME",
  "İMBAT",
  "İMBİK",
  "KATİL",
  "KATİP",
  "KATKI",
  "KATLI",
  "KATMA",
  "KATOT",
  "KATRE",
  "KAVAF",
  "KAVAK",
  "KAVAL",
  "KAVAS",
  "İMDAT",
  "İMECE",
  "İMKAN",
  "İMLEÇ",
  "İMLEK",
  "İMLİK",
  "İMREN",
  "İMROZ",
  "İMSAK",
  "KAVAT",
  "KAVGA",
  "KAVİL",
  "KAVİM",
  "KAVİS",
  "KAVKI",
  "KAVMİ",
  "KAVUK",
  "KAVUN",
  "KAVUT",
  "İNANÇ",
  "İNCİK",
  "İNCİL",
  "İNCİR",
  "İNDİS",
  "İNFAK",
  "İNFAZ",
  "İNGİN",
  "İNKAR",
  "İNMEK",
  "İNÖNÜ",
  "KAVUZ",
  "KAYAÇ",
  "KAYAK",
  "KAYAN",
  "KAYAR",
  "KAYGI",
  "KAYIK",
  "KAYIN",
  "KAYIP",
  "İNSAF",
  "İNSAN",
  "İNŞAT",
  "İNTAÇ",
  "İNTAK",
  "İNTAN",
  "İNZAL",
  "İPÇİK",
  "İPEKA",
  "İPHAM",
  "İPLİK",
  "KAYIR",
  "KAYIŞ",
  "İPSİZ",
  "İPTAL",
  "İPUCU",
  "İRADE",
  "İRADİ",
  "İRFAN",
  "İRİCE",
  "İRİTE",
  "İRKME",
  "İRMİK",
  "İRONİ",
  "KAYIT",
  "KAYMA",
  "KAYME",
  "KAYRA",
  "KAYŞA",
  "KAZAK",
  "KAZAN",
  "KAZAZ",
  "İRSAL",
  "İRSEN",
  "KAZIK",
  "KAZIL",
  "KAZIM",
  "KAZMA",
  "KEBAN",
  "KEBAP",
  "KEBİR",
  "KEBZE",
  "İRŞAT",
  "İSALE",
  "İSEVİ",
  "İSHAL",
  "İSKAN",
  "İSKOÇ",
  "İSLAM",
  "İSLAV",
  "İSLİM",
  "KEDER",
  "KEFAL",
  "KEFEN",
  "KEFİL",
  "KEFİR",
  "KEFNE",
  "KEHLE",
  "İSMEN",
  "İSMET",
  "İSNAT",
  "İSPAT",
  "İSPİR",
  "İSPİR",
  "İSPİT",
  "İSRAF",
  "İSTEK",
  "İSTEM",
  "İSTER",
  "KEKEÇ",
  "KEKİK",
  "KEKRE",
  "KELAM",
  "KELEK",
  "KELEM",
  "KELEP",
  "KELER",
  "KELES",
  "İSTİF",
  "İSTİM",
  "İSTOP",
  "İSYAN",
  "İŞEME",
  "İŞGAL",
  "İŞKAL",
  "İŞKİL",
  "İŞLEK",
  "KELEŞ",
  "KELİK",
  "KELLE",
  "KELLİ",
  "KEMAH",
  "KEMAL",
  "KEMAN",
  "KEMER",
  "KEMHA",
  "KEMİK",
  "KEMRE",
  "İŞLEM",
  "İŞLEV",
  "İŞLİK",
  "İŞMAR",
  "İŞRET",
  "İŞSİZ",
  "İŞTAH",
  "İŞTEŞ",
  "İŞYAR",
  "İTAAT",
  "İTEĞİ",
  "İTHAF",
  "JAKAR",
  "JAPON",
  "JARSE",
  "JELOZ",
  "JETON",
  "JİKLE",
  "JİLET",
  "JOKER",
  "JOKEY",
  "KENAR",
  "KENDİ",
  "KENEF",
  "KENET",
  "KENYA",
  "KEPÇE",
  "KEPEK",
  "KEPEZ",
  "KEPİR",
  "KEPME",
  "KERDE",
  "KEREM",
  "İTHAL",
  "İTHAM",
  "İTİCİ",
  "İTİLA",
  "İTİNA",
  "İTLAF",
  "İTLİK",
  "İTMAM",
  "İTMEK",
  "İVEDİ",
  "İVESİ",
  "JÜPON",
  "KERES",
  "KERİH",
  "KERİM",
  "KERİZ",
  "KERKİ",
  "KERTE",
  "KERTİ",
  "KESAT",
  "KESBİ",
  "KESEK",
  "KESEL",
  "İVMEK",
  "İYİCE",
  "İZABE",
  "İZAFE",
  "İZAFİ",
  "İZALE",
  "İZHAR",
  "İZLEK",
  "İZLEM",
  "İZLEV",
  "İZMİR",
  "KESEN",
  "KESER",
  "KESİF",
  "KESİK",
  "KESİM",
  "KESİN",
  "KESİR",
  "KESİŞ",
  "KESİT",
  "KESKİ",
  "KESME",
  "İZMİT",
  "KESRE",
  "KEŞAN",
  "KEŞAP",
  "KEŞEN",
  "KEŞİF",
  "KEŞİK",
  "İZNİK",
  "İZOLE",
  "İZZET",
  "KEŞİŞ",
  "KEŞKE",
  "KEŞKİ",
  "KETAL",
  "KETEN",
  "KETON",
  "KETUM",
  "KEVEL",
  "KEVEN",
  "KEYFİ",
  "KEYİF",
  "KIBLE",
  "KIDEM",
  "KILGI",
  "KILIÇ",
  "KILIF",
  "KILIK",
  "KILIR",
  "KILIŞ",
  "KILLI",
  "KILMA",
  "KIMIL",
  "KIMIZ",
  "KINIK",
  "KINLI",
  "KIPIK",
  "KIPMA",
  "KIPTİ",
  "KIRAÇ",
  "KIRAN",
  "KIRAT",
  "KIRAY",
  "KIRBA",
  "KIRCA",
  "KIRCI",
  "KIRIK",
  "KIRIM",
  "KIRKI",
  "KIRMA",
  "KISAS",
  "KISIK",
  "KISIM",
  "KISIR",
  "KISIŞ",
  "KISIT",
  "KISKA",
  "KISKI",
  "KISMA",
  "KISMİ",
  "KISSA",
  "KIŞIN",
  "KIŞIR",
  "KIŞLA",
  "KITAL",
  "KITIK",
  "KITIR",
  "KIVAM",
  "KIYAK",
  "KIYAM",
  "KIYAS",
  "KIYGI",
  "KIYIK",
  "KIYIM",
  "KIYIN",
  "KIYIŞ",
  "KIYMA",
  "KIYYE",
  "KIZAK",
  "KIZAN",
  "KIZIK",
  "KIZIL",
  "KIZIŞ",
  "KIZMA",
  "KİBAR",
  "KİBİR",
  "KİFAF",
  "KİKLA",
  "KİLER",
  "KİLİM",
  "KİLİS",
  "KİLİT",
  "KİLİZ",
  "KİLLİ",
  "KİLSİ",
  "KİLÜS",
  "KİMSE",
  "KİMÜS",
  "KİMYA",
  "KİNCİ",
  "KİNİK",
  "KİNİN",
  "KİNİŞ",
  "KİNLİ",
  "KİRAZ",
  "KİRDE",
  "KİREÇ",
  "KİRİL",
  "KİRİŞ",
  "KİRLİ",
  "KİRPİ",
  "KİRVE",
  "KİSVE",
  "KİTAP",
  "KİTİN",
  "KİTLE",
  "KİTRE",
  "KİZİR",
  "KLAPA",
  "KLİMA",
  "KLİPS",
  "KLİŞE",
  "KOALA",
  "KOBAY",
  "KOBRA",
  "KOÇAK",
  "KOÇAN",
  "KOÇMA",
  "KODES",
  "KOFRA",
  "KOFTİ",
  "KOFUL",
  "KOĞUŞ",
  "KOKAK",
  "KOKET",
  "KOKMA",
  "KOKOŞ",
  "KOKOT",
  "KOKOZ",
  "KOKUŞ",
  "KOLAJ",
  "KOLAN",
  "KOLAY",
  "KOLCU",
  "KOLEJ",
  "KOLİK",
  "KOLİT",
  "KOLLU",
  "KOLON",
  "KOLPO",
  "KOLSU",
  "KOLYE",
  "KOLZA",
  "KOMAR",
  "KOMBİ",
  "KOMİK",
  "KOMOT",
  "KOMŞU",
  "KOMUT",
  "KOMÜN",
  "KONAK",
  "KONDU",
  "KONİK",
  "KONMA",
  "KONSA",
  "KONUK",
  "KONUM",
  "KONUR",
  "KONUŞ",
  "KONUT",
  "KONYA",
  "KOPAL",
  "KOPÇA",
  "KOPİL",
  "KOPMA",
  "KOPOY",
  "KOPUK",
  "KOPUŞ",
  "KOPUZ",
  "KOPYA",
  "KORAL",
  "KORKU",
  "KORNA",
  "KORNO",
  "KORSE",
  "KORTE",
  "KORUK",
  "KORUN",
  "KORZA",
  "KOŞAÇ",
  "KOŞAM",
  "KOŞİN",
  "KOŞMA",
  "KOŞUK",
  "KOŞUL",
  "KOŞUM",
  "KOŞUN",
  "KOŞUT",
  "KOTAN",
  "KOTON",
  "KOTRA",
  "KOVAN",
  "KOVCU",
  "KOVMA",
  "KOVUK",
  "KOVUŞ",
  "KOYAK",
  "KOYAR",
  "KOYMA",
  "KOYUN",
  "KOYUŞ",
  "KOYUT",
  "KOZAK",
  "KOZAN",
  "KÖÇEK",
  "KÖFTE",
  "KÖHNE",
  "KÖKÇÜ",
  "KÖKEN",
  "KÖKLÜ",
  "KÖKSÜ",
  "KÖLÜK",
  "KÖMBE",
  "KÖMEÇ",
  "KÖMÜR",
  "KÖMÜŞ",
  "KÖPEK",
  "KÖPRÜ",
  "KÖPÜK",
  "KÖRPE",
  "KÖRÜK",
  "KÖSÇÜ",
  "KÖSEM",
  "KÖSNÜ",
  "KÖŞEK",
  "KÖTEK",
  "KÖYCÜ",
  "KÖYLÜ",
  "KRAÇA",
  "KRAMP",
  "KRANK",
  "KRAVL",
  "KREDİ",
  "KREMA",
  "KRİKO",
  "KROKİ",
  "KROME",
  "KROŞE",
  "KUBAT",
  "KUBBE",
  "KUBUR",
  "KUCAK",
  "KUDAS",
  "KUDUZ",
  "KUDÜM",
  "KUKLA",
  "KULAÇ",
  "KULAK",
  "KULİS",
  "KULLE",
  "KULUN",
  "KULÜP",
  "KUMAN",
  "KUMAR",
  "KUMAŞ",
  "KUMCU",
  "KUMLA",
  "KUMLU",
  "KUMRU",
  "KUMSU",
  "KUMUÇ",
  "KUMUK",
  "KUMUL",
  "KUNDA",
  "KUPES",
  "KUPLE",
  "KUPON",
  "KUPÜR",
  "KURAN",
  "KURAK",
  "KURAL",
  "KURAM",
  "KURCA",
  "KURGU",
  "KURMA",
  "KURNA",
  "KURON",
  "KURUL",
  "KURUM",
  "KURUŞ",
  "KURUT",
  "KURYA",
  "KURYE",
  "KUSMA",
  "KUSUR",
  "KUŞAK",
  "KUŞÇA",
  "KUŞÇU",
  "KUŞET",
  "KUŞKU",
  "KUTAN",
  "KUTLU",
  "KUTNU",
  "KUTSİ",
  "KUTUP",
  "KUTUR",
  "KUVER",
  "KUVÖZ",
  "KUVVE",
  "KUYTU",
  "KUYUM",
  "KUZEN",
  "KUZEY",
  "KUZİN",
  "KÜBİK",
  "KÜÇÜK",
  "KÜFLÜ",
  "KÜFÜR",
  "KÜKRE",
  "KÜLAH",
  "KÜLÇE",
  "KÜLEK",
  "KÜLLİ",
  "KÜLLÜ",
  "KÜLOT",
  "KÜLTE",
  "KÜMES",
  "KÜNCÜ",
  "KÜNDE",
  "KÜNYE",
  "KÜPLÜ",
  "KÜRAR",
  "KÜRDİ",
  "KÜREK",
  "KÜRİT",
  "KÜRSÜ",
  "KÜSKÜ",
  "KÜSME",
  "KÜSPE",
  "KÜSUF",
  "KÜSUR",
  "KÜŞAT",
  "KÜŞNE",
  "KÜŞÜM",
  "KÜTİN",
  "KÜTLE",
  "KÜTLÜ",
  "KÜTÖR",
  "KÜTÜK",
  "KÜVET",
  "ÖBÜRÜ",
  "ÖDEME",
  "ÖDLEK",
  "ÖDÜNÇ",
  "ÖĞLEN",
  "ÖĞREK",
  "ÖKSÜZ",
  "ÖLÇEK",
  "ÖLÇER",
  "ÖLÇME",
  "OBERJ",
  "OBRUK",
  "OCUMA",
  "ODACI",
  "ODALI",
  "ODEON",
  "ODSUZ",
  "OFANS",
  "OFLAZ",
  "ÖLÇÜM",
  "ÖLÇÜN",
  "ÖLÇÜŞ",
  "ÖLÇÜT",
  "ÖLGÜN",
  "ÖLMEK",
  "ÖLMEZ",
  "ÖLMÜŞ",
  "OFRİS",
  "OFSET",
  "OĞLAK",
  "OĞLAN",
  "OJELİ",
  "OKAPİ",
  "ÖNCEL",
  "ÖNCÜL",
  "ÖNDER",
  "ÖNERİ",
  "ÖNEZE",
  "ÖNLEM",
  "ÖNLÜK",
  "OKLUK",
  "OKSİT",
  "OKTAN",
  "OKTAV",
  "OKUMA",
  "OKUME",
  "OLASI",
  "OLÇUM",
  "ÖNSEL",
  "ÖPMEK",
  "ÖRCİN",
  "ÖRDEK",
  "ÖREKE",
  "ÖRGEN",
  "ÖRGÜN",
  "ÖRGÜT",
  "ÖRMEK",
  "ÖRNEK",
  "OLEİK",
  "OLEİN",
  "OLGUN",
  "OLMAK",
  "OLMAZ",
  "OLMUŞ",
  "OLURU",
  "OMBRA",
  "OMLET",
  "ONAMA",
  "ÖRTME",
  "ÖRTÜK",
  "ÖRTÜŞ",
  "ÖRÜCÜ",
  "ÖRÜLÜ",
  "ÖTEKİ",
  "ÖTMEK",
  "ÖTÜCÜ",
  "ÖTÜRÜ",
  "ONGEN",
  "ONGUN",
  "ONİKS",
  "ONLAR",
  "ONLUK",
  "ONMAK",
  "ONSUZ",
  "OOSİT",
  "OPERA",
  "ÖVMEK",
  "ÖVÜCÜ",
  "ÖVÜNÇ",
  "ÖZALP",
  "ÖZBEK",
  "ÖZDEK",
  "ÖZDEN",
  "ÖZDEŞ",
  "OPTİK",
  "ORADA",
  "ORALI",
  "ORASI",
  "ORAYA",
  "ORCİK",
  "ORFOZ",
  "ORGAN",
  "ÖZEME",
  "ÖZENÇ",
  "ÖZENİ",
  "ÖZERK",
  "ÖZGÜL",
  "ÖZGÜN",
  "ÖZGÜR",
  "ÖZLEM",
  "ÖZLÜK",
  "ORGCU",
  "ORİON",
  "ORKİT",
  "ORLON",
  "ORMAN",
  "ORTAÇ",
  "ORTAK",
  "ORTAM",
  "ORTAY",
  "ÖZNEL",
  "ÖZSEL",
  "ORTEZ",
  "ORTOZ",
  "OTACI",
  "OTAMA",
  "OTÇUL",
  "OTİST",
  "OTİZM",
  "OTLAK",
  "OTLUK",
  "OTSUL",
  "OTSUZ",
  "OVALI",
  "OVMAÇ",
  "OVMAK",
  "OYACI",
  "OYALI",
  "OYDAŞ",
  "OYLUM",
  "OYMAK",
  "OYNAK",
  "OYNAŞ",
  "OZMOZ",
  "OZUGA",
  "NABIZ",
  "NACAK",
  "NAÇAR",
  "NAÇİZ",
  "NADAN",
  "NADAS",
  "NADİM",
  "NADİR",
  "NAFİA",
  "NAFİZ",
  "NAFTA",
  "NAĞME",
  "NAHAK",
  "NAHIR",
  "NAHİF",
  "NAHİV",
  "NAHOŞ",
  "NAKDİ",
  "NAKIS",
  "NAKIŞ",
  "NAKIZ",
  "NAKİL",
  "NAKİP",
  "NAKİT",
  "NAKLİ",
  "NAKŞİ",
  "NALAN",
  "NALÇA",
  "NALIN",
  "NAMAZ",
  "NAMLI",
  "NAMLU",
  "NAMUS",
  "NANAY",
  "NANİK",
  "NARİN",
  "NASIL",
  "NASIP",
  "NASIR",
  "NASİP",
  "NASİR",
  "NAŞİR",
  "NATIR",
  "NATUK",
  "NATÜR",
  "NAZAL",
  "NAZAR",
  "NAZIM",
  "NAZIR",
  "NAZİK",
  "NAZİL",
  "NAZİR",
  "NAZLI",
  "NEBAT",
  "NECAT",
  "NECİP",
  "NEDBE",
  "NEDEN",
  "NEDİM",
  "NEFER",
  "NEFES",
  "NEFHA",
  "NEFİR",
  "NEFİS",
  "NEFİY",
  "NEFTİ",
  "NELER",
  "NEHİR",
  "NEHİY",
  "NEKES",
  "NEKRE",
  "NEMÇE",
  "NEMLİ",
  "NEMSE",
  "NESEP",
  "NESİÇ",
  "NESİH",
  "NESİL",
  "NESİM",
  "RABIT",
  "RACON",
  "RADAR",
  "RADDE",
  "RADON",
  "RADYO",
  "RAFİT",
  "RAFLI",
  "NESİR",
  "NESNE",
  "NEŞET",
  "NEŞİR",
  "NEVİR",
  "NEYÇE",
  "NEYSE",
  "NEZİF",
  "NEZİH",
  "RAFYA",
  "RAGBİ",
  "RAHAT",
  "RAHİM",
  "RAHİP",
  "RAHLE",
  "RAHNE",
  "RAKAM",
  "NEZİR",
  "NEZLE",
  "NISIF",
  "NİCEL",
  "NİÇİN",
  "NİFAK",
  "NİĞDE",
  "NİHAİ",
  "NİHAN",
  "NİKAH",
  "NİKAP",
  "RAKET",
  "RAKIM",
  "RAKİK",
  "RAKİP",
  "RAKİT",
  "RAKOR",
  "RAKUN",
  "RALLİ",
  "RAMAK",
  "RAMPA",
  "NİKEL",
  "NİMET",
  "NİNNİ",
  "NİPEL",
  "NİSAİ",
  "NİSAN",
  "NİSAP",
  "NİSPİ",
  "RANDA",
  "RANZA",
  "RAPOR",
  "RASAT",
  "RASIT",
  "RASPA",
  "RASYO",
  "RATIP",
  "RAUNT",
  "RAYBA",
  "RAYİÇ",
  "REAYA",
  "NİŞAN",
  "NİTEL",
  "NİYAZ",
  "NİYET",
  "NİZAM",
  "NİZİP",
  "NODUL",
  "NODÜL",
  "REBAP",
  "RECEP",
  "RECİM",
  "REÇEL",
  "REDİF",
  "REFAH",
  "REFİK",
  "REFÜJ",
  "REHİN",
  "NOGAY",
  "NOHUT",
  "NOKRA",
  "NOKTA",
  "NONOŞ",
  "NOTAM",
  "NOTER",
  "NÖBET",
  "NÖRON",
  "REJİM",
  "REKAT",
  "REKİZ",
  "REKOR",
  "REMEL",
  "REMİL",
  "REMİZ",
  "RENDE",
  "NUKUT",
  "NUMEN",
  "NURLU",
  "NUTUK",
  "NÜANS",
  "NÜFUS",
  "NÜFUZ",
  "NÜKTE",
  "NÜKUL",
  "NÜSHA",
  "RESEN",
  "RESİF",
  "RESİM",
  "RESMİ",
  "RESUL",
  "REŞİT",
  "REŞME",
  "NÜZUL",
  "REVAÇ",
  "REVAK",
  "REVAN",
  "REVİR",
  "REVİŞ",
  "REYON",
  "REZİL",
  "RIZIK",
  "RİCAL",
  "RİCAT",
  "RİJİT",
  "RİMEL",
  "RİNGA",
  "RİTİM",
  "RİYAL",
  "ROBOT",
  "RODAJ",
  "RODEO",
  "ROKET",
  "ROLCÜ",
  "ROMAN",
  "ROMEN",
  "ROSTO",
  "ROTİL",
  "ROTOR",
  "ROZET",
  "RÖFLE",
  "RÖGAR",
  "RÖTAR",
  "RÖTUŞ",
  "RUBAİ",
  "RUBLE",
  "RUFAİ",
  "RUGAN",
  "RUHÇA",
  "RUHEN",
  "RUHLU",
  "RUJLU",
  "RULET",
  "RUMBA",
  "RUMCA",
  "RUMUZ",
  "RUNİK",
  "RUSÇA",
  "RUTİN",
  "RÜESA",
  "RÜKÜN",
  "RÜKÜŞ",
  "RÜSUM",
  "RÜSUP",
  "RÜSVA",
  "RÜTBE",
  "RÜYET",
  "MAADA",
  "MABAT",
  "MABET",
  "MABUT",
  "MACAR",
  "MACUN",
  "MAÇKA",
  "MADAM",
  "MADDE",
  "MADDİ",
  "MADEM",
  "MADEN",
  "MADER",
  "MADİK",
  "MADUN",
  "MAFİŞ",
  "MAFYA",
  "MAGMA",
  "MAGRİ",
  "MAHAL",
  "MAHFE",
  "MAHFİ",
  "MAHIV",
  "MAHİR",
  "MAHRA",
  "MAHUR",
  "MAHUT",
  "MAHYA",
  "MAİLE",
  "MAJÖR",
  "MAKAK",
  "MAKAM",
  "MAKAS",
  "MAKAT",
  "MAKET",
  "MAKRO",
  "MAKSİ",
  "MAKTA",
  "MAKTU",
  "MAKUL",
  "MAKUS",
  "MALAK",
  "MALAZ",
  "MALCA",
  "MALCI",
  "MALEN",
  "MALİK",
  "MALUL",
  "MALUM",
  "MALYA",
  "MAMAK",
  "MAMBO",
  "MAMUL",
  "MAMUR",
  "MAMUT",
  "MANAS",
  "MANAT",
  "MANAV",
  "MANCA",
  "MANÇU",
  "MANDA",
  "MANEJ",
  "MANEN",
  "MANGA",
  "MANGO",
  "MANİA",
  "MANTI",
  "MANTİ",
  "MANTO",
  "MAOCU",
  "MAPUS",
  "MARAL",
  "MARAZ",
  "MARDA",
  "MARİZ",
  "MARKA",
  "MARKE",
  "MARKİ",
  "MARON",
  "MARTI",
  "MARUF",
  "MARUL",
  "MARUZ",
  "MARYA",
  "MASAJ",
  "MASAL",
  "MASAT",
  "MASİF",
  "MASKE",
  "MASNU",
  "MASON",
  "MASÖR",
  "PABUÇ",
  "MASÖZ",
  "PAÇAL",
  "PAÇOZ",
  "PADOK",
  "PAFTA",
  "PAGAN",
  "MASTI",
  "MASUM",
  "MASUN",
  "MAŞER",
  "MAŞUK",
  "MATAH",
  "MATBU",
  "MATEM",
  "MATİZ",
  "PAHAL",
  "PAKET",
  "PALAN",
  "PALAS",
  "PALAZ",
  "PALET",
  "PALTO",
  "PAMPA",
  "PAMUK",
  "MATLA",
  "MATUF",
  "MATUH",
  "MAVAL",
  "MAVİŞ",
  "MAVNA",
  "MAVRA",
  "MAYIN",
  "MAYIS",
  "MAYNA",
  "PANDA",
  "PANEL",
  "PANİK",
  "PAPAK",
  "PAPAZ",
  "PAPEL",
  "PARAF",
  "PARÇA",
  "PARKA",
  "MAZAK",
  "MAZOT",
  "MAZUR",
  "PARKE",
  "PARPA",
  "PARSA",
  "PARTİ",
  "PARYA",
  "PASAJ",
  "PASAK",
  "PASÇI",
  "PASİF",
  "MEBDE",
  "MEBİZ",
  "MEBNİ",
  "MEBUS",
  "MECAL",
  "MECAZ",
  "MECMU",
  "MECRA",
  "MECUS",
  "MEDAR",
  "MEDET",
  "MEDİH",
  "PASLI",
  "PASÖR",
  "PASTA",
  "PATAK",
  "PATAL",
  "PATEN",
  "PATİK",
  "MEDÜZ",
  "MEDYA",
  "MEFUL",
  "MEĞER",
  "MEHAZ",
  "MEHDİ",
  "MEHİL",
  "MEHLE",
  "PATOZ",
  "PAUNT",
  "MEKAN",
  "MEKİK",
  "MELAL",
  "MELAS",
  "MELCE",
  "MELEK",
  "PAYAN",
  "PAYDA",
  "PAYEN",
  "PAYET",
  "PAYLI",
  "PAZAR",
  "PAZEN",
  "MELES",
  "MELEŞ",
  "MELEZ",
  "MELİK",
  "MELON",
  "MELUL",
  "MELUN",
  "MEMAT",
  "MEMBA",
  "MEMNU",
  "MEMUL",
  "PEÇİÇ",
  "PEDAL",
  "PEDER",
  "PEKÇE",
  "PELİN",
  "PELİT",
  "PELTE",
  "PELÜR",
  "PELÜŞ",
  "PEMBE",
  "PENÇE",
  "PENES",
  "MEMUR",
  "MENFA",
  "MENFİ",
  "PENGÖ",
  "PENİS",
  "PENSE",
  "PENYE",
  "PERDE",
  "PEREN",
  "PERKİ",
  "PERMA",
  "PERMİ",
  "PERON",
  "MENŞE",
  "MENUS",
  "MERAK",
  "MERAM",
  "MERCİ",
  "MEREK",
  "MERES",
  "MERET",
  "MERİÇ",
  "MERİH",
  "MERMİ",
  "MESAİ",
  "PERUK",
  "PERVA",
  "PESEK",
  "PESÜS",
  "PEŞİN",
  "PEŞLİ",
  "PEŞTU",
  "PETEK",
  "PEYDA",
  "MESAJ",
  "MESEL",
  "MESEN",
  "MESİH",
  "MESMU",
  "PEYKE",
  "PIHTI",
  "PINAR",
  "PIRPI",
  "PIRTI",
  "PISMA",
  "PİGME",
  "PİKAJ",
  "PİKAP",
  "PİKET",
  "PİLAV",
  "MESUL",
  "MESUT",
  "MEŞBU",
  "MEŞİN",
  "MEŞRU",
  "MEŞUM",
  "METAL",
  "METAN",
  "METBU",
  "PİLİÇ",
  "PİLLİ",
  "PİLOT",
  "PİNEL",
  "PİNES",
  "PİNTİ",
  "PİPET",
  "PİRİT",
  "METİL",
  "METİN",
  "METİS",
  "METOT",
  "METRE",
  "METRO",
  "MEVDU",
  "MEVKİ",
  "MEVLA",
  "MEVTA",
  "MEVUT",
  "PİSİK",
  "PİSİN",
  "PİŞEK",
  "PİŞİK",
  "PİŞİM",
  "PİŞME",
  "PİŞTİ",
  "PİTON",
  "PİYAN",
  "PİYAZ",
  "MEVZİ",
  "MEVZU",
  "MEYAN",
  "MEYİL",
  "MEYUS",
  "MEYVE",
  "MEZAR",
  "MEZAT",
  "MEZON",
  "PİYES",
  "PİYON",
  "PİZZA",
  "PLAKA",
  "PLASE",
  "PLATİ",
  "MEZRA",
  "MEZRU",
  "MEZUN",
  "MEZÜR",
  "MEZZO",
  "MICIR",
  "MIGIR",
  "MIGRİ",
  "PLATO",
  "PLAZA",
  "POKER",
  "POLAR",
  "POLAT",
  "POLEN",
  "POLİM",
  "POLİP",
  "POLİS",
  "MIHLI",
  "MIRRA",
  "MISIR",
  "MISRA",
  "MİÇEL",
  "MİDYE",
  "MİKAP",
  "POLKA",
  "POLÜM",
  "POMAK",
  "POMAT",
  "POMPA",
  "MİKOZ",
  "MİKRO",
  "MİLAS",
  "MİLAT",
  "MİLEL",
  "MİLİM",
  "MİLİS",
  "MİLLİ",
  "MİMAR",
  "MİMİK",
  "PONJE",
  "PONZA",
  "POPÇU",
  "PORNO",
  "PORTE",
  "PORTO",
  "POSOF",
  "POSTA",
  "MİMLİ",
  "MİNİK",
  "MİNÖR",
  "MİRAÇ",
  "MİRAS",
  "MİRAT",
  "MİRZA",
  "MİSAK",
  "MİSAL",
  "POŞET",
  "POTAS",
  "POTİN",
  "POTUK",
  "POTUR",
  "POYRA",
  "MİSEL",
  "MİSİL",
  "MİSİS",
  "MİTİL",
  "MİTOS",
  "MİTOZ",
  "PÖÇÜK",
  "PRAFA",
  "PRAYA",
  "PRENS",
  "PRESE",
  "PROJE",
  "PROVA",
  "PRUVA",
  "PUDRA",
  "PUFLA",
  "PULCU",
  "MİYAR",
  "MİYAV",
  "MİYAZ",
  "MİYOM",
  "MİYOP",
  "MİZAÇ",
  "MİZAH",
  "MİZAN",
  "MOBİL",
  "PULLU",
  "PULSU",
  "PULUÇ",
  "PUMBA",
  "PUNTO",
  "PUSAT",
  "PUSET",
  "PUSLU",
  "PUSMA",
  "PUVAR",
  "PÜNEZ",
  "MODEL",
  "MODEM",
  "MODÜL",
  "MOĞOL",
  "MOHER",
  "MOLAS",
  "MOLLA",
  "MOLOZ",
  "MONAT",
  "MONTE",
  "MOPET",
  "MORAL",
  "PÜREN",
  "PÜRÜZ",
  "PÜSÜR",
  "PÜTÜR",
  "MOREN",
  "MORTO",
  "MORUK",
  "MOTEL",
  "MOTİF",
  "MOTOR",
  "MOTTO",
  "MOZAK",
  "MÖBLE",
  "MÖSYÖ",
  "MUARE",
  "MUCİP",
  "MUCİR",
  "MUCİT",
  "MUCUK",
  "MUCUR",
  "MUFLA",
  "MUĞLA",
  "MUHAL",
  "MUHAT",
  "MUHİK",
  "MUHİL",
  "MUHİP",
  "MUHİT",
  "MUJİK",
  "MUKİM",
  "MUKNİ",
  "MUKUS",
  "MULAJ",
  "MUMCU",
  "MUMLU",
  "MUMYA",
  "MUNİS",
  "MURAT",
  "MURİS",
  "MUSAP",
  "MUSIR",
  "MUSKA",
  "MUSON",
  "MUŞLU",
  "MUŞTA",
  "MUŞTU",
  "MUTAF",
  "MUTAT",
  "MUTÇU",
  "MUTKİ",
  "MUTLU",
  "MUYLU",
  "MUZIR",
  "MUZİP",
  "MUZSU",
  "MÜBAH",
  "MÜDÜR",
  "MÜFİT",
  "MÜFTÜ",
  "MÜHİM",
  "MÜHRE",
  "MÜHÜR",
  "MÜJDE",
  "MÜLGA",
  "MÜLKİ",
  "MÜMAS",
  "MÜMİN",
  "MÜNŞİ",
  "MÜRAİ",
  "MÜRİT",
  "MÜRUR",
  "MÜŞİR",
  "MÜZİÇ",
  "MÜZİK",
];
